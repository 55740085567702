import React, { useState, useContext } from "react";
import "./style.scss";
import imgIcon from "./../../Assets/img-icon-green.png";
import ringingYellowDot from "./../../Assets/ringing_yellow_dot.png";
import { CURRENCY_SYMBOL } from "./../../Config";
import Switch from "../Switch";
import { useDispatch, useSelector } from "react-redux";
import { fetchCatalogItemById } from "../../Redux/Actions";
import blueArrow from "./../../Assets/blue-arrow.svg";
import { ParentContext } from "../../parentProvider";
import { isStoreTypeB2b, versionCompare } from "../../Utils/common";

const CatalogItem = (props) => {
  const contextData = useContext(ParentContext);
  const { version_number, app_version_code, storeid } = contextData;
  const dispatch = useDispatch();
  const [openVariantsTable, setOpenVariantsTable] = useState({});
  const isB2bStore = isStoreTypeB2b();

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.data,
  }));

  const variantFetchingOnClick = (id) => {
    // if variant items exist in state, remove them
    if (+Object.keys(openVariantsTable)[0] === id) {
      setOpenVariantsTable({});
    } else {
      setOpenVariantsTable({
        ...openVariantsTable,
        [id]: {
          flag: false,
          data: [0, 0, 0],
          loading: true,
        },
      });
      dispatch(
        fetchCatalogItemById(
          { store_id: storeid, item_id: id },
          false,
          (data) => {
            if (data) {
              setOpenVariantsTable({
                ...openVariantsTable,
                [id]: {
                  flag: true,
                  data: data?.variants,
                  loading: false,
                },
              });
            } else {
              setOpenVariantsTable({});
            }
          }
        )
      );
    }
  };

  const renderItemVariant = () => {
    const variant = openVariantsTable[props.data.id] ?? {};
    return !!Object.keys(openVariantsTable)
      ? variant?.loading && variant?.flag
        ? null
        : variant?.flag && (
            <section className="v-catalog-item--variant-list">
              {!!variant?.data?.length &&
                variant?.data?.map((_var) => (
                  <div key={_var.id} className="variant-item flex">
                    <span className="variant-item__img">
                      <img
                        src={
                          _var?.images ? _var?.images[0]?.image_url : imgIcon
                        }
                        alt="variant image"
                        onClick={props.onItemClick}
                      />
                    </span>
                    <div className="flex items-center pr2 w-100">
                      <div className="flex flex-column justify-between h-100 w-100">
                        <p
                          className="variant-name mv0"
                          onClick={props.onItemClick}
                        >
                          {_var.variant_name}
                        </p>
                        <div>
                          {isB2bStore ? (
                            <span
                              className="discounted-price"
                              onClick={props.onItemClick}
                            >
                              {_var?.b2b_pricing_info?.price_range || "Pricing on Request"}
                            </span>
                          ) : (
                            <>
                            {_var.discounted_price !== _var.price && (
                              <span
                                className="strike main-price"
                                onClick={props.onItemClick}
                              >
                                {CURRENCY_SYMBOL}
                                {_var.price}
                              </span>
                            )}
                            <span
                              className="discounted-price"
                              onClick={props.onItemClick}
                            >
                              {CURRENCY_SYMBOL}
                              {_var.discounted_price}
                            </span>
                          </>
                          )}
                        </div>
                      </div>
                      {versionCompare(
                        app_version_code ? app_version_code : version_number
                      ) && (
                        <div className="flex items-center">
                          <div className="relative stock-component flex flex-column justify-center items-center">
                            {!!_var.managed_inventory && (
                              <span
                                className="stock-amount flex justify-center"
                                onClick={() =>
                                  props.inventoryStockValChange(
                                    _var,
                                    props.data
                                  )
                                }
                              >
                                {_var.available_quantity}
                              </span>
                            )}
                            <div className="stock-label">
                              {_var.available ? "(in-stock)" : "(out-of-stock)"}
                            </div>
                          </div>
                          <Switch
                            active={_var.available}
                            toggleHandle={() =>
                              props.onVariableStockChange(_var)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </section>
          )
      : null;
  };

  return (
    <article className="v-catalog-item">
      <section className="flex">
        <div style={{position: "relative"}} className="v-catalog-item__img" onClick={props.onItemClick}>
          {props.hasSeoRecommendation && <img className="recommendation-dot" src={ringingYellowDot}
          style={{
            top: props.data.variants_count > 1 ?'-6px':'-3px',
            right: props.data.variants_count > 1 ?'-6px':'-3px'
          }} />}
          {props.data.variants_count > 1 ? (
            <span className="relative image-container--variant">
              <span className="absolute z-1" />
              <span className="absolute z-2" />
              <span className="absolute z-3">
                <img src={props.data.image_url || imgIcon} />
              </span>
            </span>
          ) : (
            <span className="image-container">
              <img src={props.data.image_url || imgIcon} />
            </span>
          )}
        </div>
        <div className="v-catalog-item__box">
          <div
            className="v-catalog-item__box--name fw5"
            onClick={props.onItemClick}
          >
            {props.data.name || "Item Name"}
          </div>
          {props.data.variants_count > 0 ? (
            <span
              onClick={() => variantFetchingOnClick(props.data.id)}
              className={`v-catalog-item__box--variant-toggle flex items-center pointer ${
                openVariantsTable[props.data.id] ? "active" : ""
              }`}
            >
              {openVariantsTable[props.data.id] ? (
                <>
                  Hide Variants <img src={blueArrow} className="ml2" />
                </>
              ) : (
                <>
                  {props.data.variants_count} Variant
                  {props.data.variants_count > 1 && "s"} Available{" "}
                  <img src={blueArrow} className="ml1" />
                </>
              )}
            </span>
          ) : (
            <div className="v-catalog-item__box--wrap">
              <div
                className="v-catalog-item__box--price"
                onClick={props.onItemClick}
              >
                {isB2bStore ? (
                  <span className="v-catalog-item__box--price-actual">
                    {props.data?.b2b_pricing_info?.price_range || "Pricing on Request"}
                  </span>
                ) : (
                  <>
                    {props.data.discounted_price !== props.data.price && (
                      <span className="v-catalog-item__box--price-dis">
                        {CURRENCY_SYMBOL}
                        {props.data.price}
                      </span>
                    )}
                    <span className="v-catalog-item__box--price-actual">
                      {CURRENCY_SYMBOL}
                      {props.data.discounted_price}
                    </span>
                  </>
                )}
              </div>
              <div className="v-catalog-item__box--stock">
                <span className="relative flex flex-column justify-center items-center">
                  {!!props.data.managed_inventory && (
                    <span
                      className="stock-amount flex justify-center"
                      onClick={() =>
                        props.inventoryStockValChange(null, props.data)
                      }
                    >
                      {props.data.available_quantity}
                    </span>
                  )}
                  <span className="stock-declaration">
                    {props.data.available ? "(in-stock)" : "(out-of-stock)"}
                  </span>
                </span>
                <Switch
                  active={props.data.available}
                  toggleHandle={props.onStockChange}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      {!!props.data.variants_count && (
        <section className="v-catalog-item--variant-container">
          {renderItemVariant(props.data.variant)}
        </section>
      )}
    </article>
  );
};

export default CatalogItem;
