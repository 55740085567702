import store from "../Redux/Store";
import { getDaysDiffFromToday } from "./common";
import { WORKSPACE_INFO_STATUS, WORKSPACE_USER_STATUS } from "./constants";

const {
  workspaceInfo,
  workspacePrimaryUser,
  workspaceUsersList,
  allWorkspaceUsers
} = store.getState().workspaceReducer

/**
 * Checks whether there are any users with statuses
 * between 5 and 15 (both inclusive)
 * @param users
 * @returns boolean
 */
export const checkForUnAccountedStatusOfEmails = (users) => {
  let unAccountedEmails = [];
  unAccountedEmails = users?.filter(
    (user) =>
      user.status >= WORKSPACE_USER_STATUS.STUCK_WHILE_SETTING_AUTHENTICATION &&
      user.status <= WORKSPACE_USER_STATUS.STUCK_DURING_ENTITLEMENT_UPDATION,
  );

  return !!unAccountedEmails.length;
};

/**
 * checks whether the given email data status
 * is 0 or 4. if status is 0 or 4, return false
 * else return true
 * @param accountStatus
 * @returns boolean
 */
export const checkEmailStatusForInProgOrPayRecieved = (accountStatus) => {
  switch (accountStatus) {
    case WORKSPACE_USER_STATUS.IN_PROGRESS:
    case WORKSPACE_USER_STATUS.PAYMENT_RECEIVED:
      return false;
    default:
      return true;
  }
};

export const checkForWorkspaceStatusEqualingArgs = (workspace_status) => workspaceInfo?.status === workspace_status;

/**
* check if any of the email status is between 5 and 15
* @param emailList
* @returns boolean
*/
export const checkIfAnyEmailOutOfBounds = (emailList) => {
  let emails = [];
  emails = emailList?.filter((user) => user?.status >= WORKSPACE_USER_STATUS.STUCK_WHILE_SETTING_AUTHENTICATION && user?.status <= WORKSPACE_USER_STATUS.STUCK_DURING_ENTITLEMENT_UPDATION);
  return !!emails.length;
};

export const workspaceInErrorState = (workspace) => {
  return workspace?.status === WORKSPACE_INFO_STATUS.WORKSPACE_STATUS_EXPIRED || workspace?.status === WORKSPACE_INFO_STATUS.WORKSPACE_SUSPENDED_DUE_TO_DOMAIN_EXPIRY || workspace?.status === WORKSPACE_INFO_STATUS.WORKSPACE_ACC_CANCELLED_DUE_TO_TOS_NOT_ACCEPTED
}

/**
 * function to check whether workspace account
 * is in expiration phase
 * @returns array
 */
export const isWorkspaceAccountExpiring = () => {
  return (
    getDaysDiffFromToday(workspaceInfo?.expiration_time) <= 30 &&
    getDaysDiffFromToday(workspaceInfo?.expiration_time) >= 0
  );
};

/**
 * 
 * @param {number} status 
 * @returns boolean
 */
export const checkIfAnyUserNotInRequestedStatus = (status) => {
  return allWorkspaceUsers?.length > 0
    ? allWorkspaceUsers?.some((user) => user?.status !== status)
    : false;
}