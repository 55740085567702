import React, { useEffect, useMemo, useState } from "react";
import { CURRENCY_SYMBOL } from "../../../Config";
import Modal from "../../Modal";
import "./style.scss";
import ButtonDec from "../../../Assets/button-dec-icon.svg";
import ButtonInc from "../../../Assets/button-inc-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  getWorkspacePriceDetails,
  initiateWorkspacePurchase,
} from "../../../Redux/Actions";
import { getUrlParams } from "../../../Utils/getUrlParams";

const WorkspacePurchaseModal = (props) => {
  const [chosenDomain] = getUrlParams(["domain"]);
  const dispatch = useDispatch();
  const [emailCount, setEmailCount] = useState(1);
  const [apiActive, setApiActive] = useState(false);

  const { workspacePricingList } = useSelector((state) => ({
    workspacePricingList: state.workspaceReducer.workspacePricingList,
  }));

  const domain = props.domain || chosenDomain;

  useEffect(() => {
    if (!props.show) setEmailCount(1);
  }, [props.show]);

  useEffect(() => {
    if (props.show && domain) {
      dispatch(
        getWorkspacePriceDetails({
          count: emailCount,
          domain: domain,
        })
      );
    }
  }, [domain, emailCount]);

  const workspacePrice = useMemo(() => {
    if (workspacePricingList?.length) return workspacePricingList[0];
    else return null;
  }, [workspacePricingList]);

  const changeValue = (type) => {
    switch (type) {
      case "dec":
        if (emailCount > 1) setEmailCount((s) => s - 1);
        break;
      case "inc":
        if (emailCount < 5) setEmailCount((s) => s + 1);
      default:
        break;
    }
  };

  const payBtnClick = () => {
    if (!apiActive) {
      setApiActive((s) => !s);

      const payload = {
        purchase_price: workspacePrice.total_price,
        count: emailCount,
        domain: domain,
        plan_type: workspacePrice.plan_type,
      };

      dispatch(
        initiateWorkspacePurchase(payload, (status) => {
          if (status) {
            props.toggleModal();
            props.togglePaymentModal();
          }
          setApiActive((s) => !s);
        })
      );
    }
  };

  return (
    <Modal
      visible={props.show}
      className="bottom-modal workspace-purchase-modal"
      animation={"slideUp"}
      onClose={props.toggleModal}
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      <div className="modal-header">
        <h3>Get Professional Email</h3>
        <p>For 1 year</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="support-text pr3 mv0">
          Choose number of users you need.
          <br />
          Each user gets a professional email
        </p>
        <EmailCountButton
          changeValue={changeValue}
          emailCount={emailCount}
          minValue={emailCount <= 1}
          maxValue={emailCount >= 5}
        />
      </div>
      <div className="pricing-component">
        <div className="pricing-row">
          <p>Price of {emailCount} user(s)</p>
          <p>
            {CURRENCY_SYMBOL}
            {workspacePrice?.mrp}
          </p>
        </div>
        <div className="pricing-row">
          <p>{workspacePrice?.gst_percent}% GST</p>
          <p>
            {CURRENCY_SYMBOL}
            {workspacePrice?.gst_value}
          </p>
        </div>
        {!!workspacePrice?.discount_value ? (
          <div className="pricing-row">
            <p>Discount</p>
            <p style={{ color: "#05BE00" }}>
              {CURRENCY_SYMBOL}
              {workspacePrice?.discount_value}
            </p>
          </div>
        ) : null}
      </div>
      <div className="pricing-component total-pricing">
        <div className="pricing-row">
          <p>Price of {emailCount} user(s)</p>
          <p>
            {CURRENCY_SYMBOL}
            {workspacePrice?.total_price}
          </p>
        </div>
      </div>
      <button className="payment-button" onClick={payBtnClick}>
        Pay Now
      </button>
    </Modal>
  );
};

export default WorkspacePurchaseModal;

const EmailCountButton = React.memo((props) => {
  return (
    <button className="email-button">
      <span
        className={`controls ${props.minValue ? "o-20" : ""}`}
        onClick={() => props.changeValue("dec")}
      >
        <img src={ButtonDec} />
      </span>
      <span className="value">{props.emailCount}</span>
      <span
        className={`controls ${props.maxValue ? "o-20" : ""}`}
        onClick={() => props.changeValue("inc")}
      >
        <img src={ButtonInc} />
      </span>
    </button>
  );
});
