import React from "react";
import { WORKSPACE_AVAILABLE_GOOGLE_APPS } from "../../Utils/constants";
import "./style.scss";

const AvailableGoogleApps = () => {
  const onAppIconClick = (data) => {
    if (window.Android) {
      // if (data.androidAppLink) {
      //     window.Android.sendData(JSON.stringify({
      //         openAppByPackage: true,
      //         data: data.androidAppLink
      //     }));
      // } else {
      //     window.open(data.link, '_blank');
      // }
      window.Android.sendData(
        JSON.stringify({
          openAppByPackage: true,
          data: data.androidAppLink,
        })
      );
    } else {
      //   if (data.iosAppLink) {
      //       window.ReactNativeWebView &&
      //         window.ReactNativeWebView.postMessage(JSON.stringify({
      //           openAppByPackage: true,
      //           data: data.iosAppLink,
      //           id: data.iosAppId,
      //           url: data.link
      //       }));
      //   } else {
      //     window.open(data.link, '_blank');
      //   }
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            openAppByPackage: true,
            data: data.iosAppLink,
            id: data.iosAppId,
            url: data.link,
          })
        );
    }
  };

  return (
    <section className="available-apps mb4">
      <p className="f14px black ph3 tc mt0 mb24px">Get addition apps to enhance productivity</p>
      <ul className="apps-list">
        {WORKSPACE_AVAILABLE_GOOGLE_APPS.map((app) => {
          return (
            <li
              className="flex flex-column items-center gray"
              onClick={() => onAppIconClick(app)}
            >
              <span className="app-icon flex justify-center items-center">
                <img src={app.imgSrc} alt="image for apps" />
              </span>
              <div>
                <article className="f10px tc mt2">{app.text1}</article>
                <article className="f10px tc mt1">{app.text2}</article>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default React.memo(AvailableGoogleApps);