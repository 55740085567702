import React from "react";
import back from "../../Assets/return.png";
import "./styles.scss";
import history from "../../Utils/history";
import { goBackToApp } from "../../Utils/androidEvents";

/**
 * Fixed Header
 * apply padding-top/margin-top: var(--fixed-header-height); to page main layout else use custom main page
 * component that does the same
 *  */

export default function FixedHeader({
  heading = "",
  onBackBtnClick,
  hideShadow = false,
  customStyle = {},
  unmountWebview = true,
}) {
  const onBackIconClick = () => {
    if (onBackBtnClick) {
      onBackBtnClick();
      return;
    }
    if (unmountWebview) {
      goBackToApp();
      return;
    }
    history.goBack();
  };

  const style = { backgroundColor: "#fff", ...customStyle };

  return (
    <header
      className={`common-page-header-wrapper ${
        hideShadow ? "hide-shadow" : ""
      }`}
      style={style}
    >
      <button onClick={onBackIconClick} className="btn-reset back-btn">
        <img src={back} />
      </button>
      <p className="page-heading">{heading}</p>
    </header>
  );
}
