import React, { useEffect, useState } from "react";
import Modal from "./../Modal";
import closeIcon from "./../../Assets/close.png";
import "./style.scss";
import Input from "../Input";
import { useDispatch, useSelector } from "react-redux";
import {
  addCatalogItem,
  setAddProductModalVisible,
  fetchCatalogItemsByCategoryId,
} from "../../Redux/Actions";
import { ToastNotifyError } from "../Toast";

const AddProductPopUp = (props) => {
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const commonData = useSelector((state) => state.commonReducer);
  const loaderData = useSelector((state) => state.loaderReducer);
  const categories = useSelector((state) => state.catalogReducer);
  const { catalog_list } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");

  useEffect(() => {
    if (commonData.isItemModalVisible === false) {
      setItemName("");
      setItemPrice("");
    }
  }, [commonData.isItemModalVisible]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      categories.forEach((category) => {
        dispatch(
          fetchCatalogItemsByCategoryId({ store_id: store_id, category })
        );
      });
    }
  }, [categories]);

  const onDataChange = (type, change) => {
    switch (type) {
      case "name":
        setItemName(change);
        break;
      case "price":
        setItemPrice(change);
        break;
    }
  };

  const onAddItemBtnClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const store_id = urlParams.get("storeid");
    const token = urlParams.get("token");
    window.auth_token = token;
    if (!itemName?.trim()) {
      ToastNotifyError("Item Name empty!");
      return;
    }
    dispatch(
      addCatalogItem(
        {
          store_id: Number(store_id),
          name: itemName,
          price: Number(itemPrice),
          item_type: "catalog",
        },
        () => props.updateList()
      )
    );
  };

  const onCloseAction = () => {
    dispatch(setAddProductModalVisible(false));
  };

  return (
    <Modal
      visible={commonData.isItemModalVisible}
      className="bottom-modal category-list-modal"
      height={200}
      animation={"slideUp"}
      onClose={onCloseAction}
    >
      <span className="modal-close-icon" onClick={onCloseAction}>
        <img src={closeIcon} />
      </span>
      <div className="addproduct-popup">
        {/* <div className="addproduct-popup--title">
                    Add Item
                </div> */}
        <div className="addproduct-popup__form">
          <Input
            value={itemName}
            className="addproduct-popup__form--input"
            placeholder={
              catalog_list?.placeholder_item_name_size_text ||
              "Item Name & Size"
            }
            type="text"
            onChange={(e) => onDataChange("name", e.target.value)}
          />
          <Input
            value={itemPrice}
            className="addproduct-popup__form--input"
            placeholder={catalog_list?.placeholder_prize_text || "Price (₹)"}
            type="number"
            onChange={(e) => onDataChange("price", e.target.value)}
          />
          <button
            onClick={onAddItemBtnClick}
            disabled={loaderData.isLoaderVisible}
            className={`${loaderData.isLoaderVisible ? "btn-disable" : ""}`}
          >
            {catalog_list?.add_item_btn_text || "Add Item"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddProductPopUp;
