import React, { useEffect, useState } from "react";
import "./style.scss";

const Switch = (props) => {

  const className = `toggle-component ${props.active ? "active" : ""}`;

  return (
    <article className="switch-comp">
      <div className={className} onClick={props.toggleHandle}>
        <div className="toggle-button" style={{ background: `${props.active ? '#1E9848' : '#e23636'}`}}/>
      </div>
    </article>
  );
};

export default Switch;
