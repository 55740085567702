import React from "react";
import tickDark from "./../../Assets/ic_blacktick.png";
import crossBlack from "./../../Assets/cross-black.png";
import "./styles.scss";

const Chip = (props) => {
  return (
    <button
      className="ds-chip-card"
      key={props.compKey || `chip-${Date.now()}`}
      onClick={props.onCross}
    >
      <img src={tickDark} />
      <label>{props.label}</label>
      <img src={crossBlack} />
    </button>
  );
};

export default Chip;
