import React from 'react';
import './style.scss';
import addIcon from './../../Assets/add_circle.png';
import PaperListItem from './PaperListItem';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from './../../Redux/Actions/index';

const CreatePaperList = () => {

    const cartData = useSelector((state) => state.cartReducer.data);
    const dispatch = useDispatch();

    const onCartItemDataChanges = (type, change, index) => {
        let tempItems =[];
       
        switch (type) {
            case 'item':  tempItems = [...cartData.items];
                tempItems[index].item_name = change;  
                break;
            case 'qty':
                tempItems = [...cartData.items];
                tempItems[index].item_quantity = change;    
                break;
            case 'price': 
                tempItems = [...cartData.items];
                tempItems[index].item_price = change ? Number(change) : '';
                tempItems[index].discounted_price = change ? Number(change) : '';
                tempItems[index].price = change ? Number(change) : '';
                break;
            case 'add':  tempItems = [...cartData.items];
                tempItems.push({id: Date.now(),
                    item_name: "",
                    item_quantity: "",
                    item_price: '',
                    price: '',
                    discounted_price: '',
                    item_type: "list",});
                break;
            case 'remove': tempItems = [...cartData.items];
                tempItems.splice(index, 1);
                break;
            default: return;
        }
        dispatch(addToCart({ ...cartData, items : tempItems }));
    }

    const paperlist = cartData.items.filter(ele => ele.item_type === 'list');

    return (
        <div className="create-paperlist">
           {
               paperlist.map((item,idx) =>{
                   const objIdx = cartData.items.findIndex(ele => ele.id === item.id);
                   return <PaperListItem 
                        key={idx+'create-paper-list-items'}
                        name={item.item_name}
                        quantity={item.item_quantity}
                        price={item.item_price}
                        onHandleItemNameChange={text => onCartItemDataChanges('item', text, objIdx)}
                        onHandleQtyChange={qty => onCartItemDataChanges('qty', qty, objIdx)}
                        onHandlePriceChange={price => onCartItemDataChanges('price', price, objIdx) }
                        onHandleRemoveItemClicked={() => onCartItemDataChanges('remove','', objIdx)}/>
               })
           }
            { paperlist.length !== 0 && <div className="create-paperlist__add">
                <span 
                    className="addmore-btn"
                    onClick={()=>onCartItemDataChanges('add')}
                >
                    ADD MORE
                    {' '} <img 
                            src={addIcon}
                            alt="Add more button icon"/>
                </span>
            </div> }
        </div>
    )
}

export default CreatePaperList;