import React, { useContext } from "react";
import { Modal } from "../..";
import { ParentContext } from "../../../parentProvider";
import history from "../../../Utils/history";
import WorkSpaceFeatureIconList from "../WorkSpaceFeatureIconList";
import './styles.scss';

const WorkspaceDomainPurchaseHook = props => {
    const contextData = useContext(ParentContext);
    const { additionalParameters, token, storeid } = contextData;

    const onAvailableDomainClick = () => {
        history.push(`/domain-explore${additionalParameters}`);
    }
    const onDomainConnectClick = () => {
        history.push(`/domain-connect${additionalParameters}`);
    }

    return (
        <Modal
            visible={props.show}
            customStyles={{
            padding: 0,
            }}
            className="bottom-modal workspaceDomainPurchaseHook"
            animation={"slideUp"}
            onClose={props.onClose}
        >
            <section className="pa3">
                <p className="f12px black-50">You will get professional email ID & more</p>
                <WorkSpaceFeatureIconList noName={true}/>

                <p className="fw6 f24px mb2 mt5">No Domain Found!</p>
                <p className="f14px fw3 black-50 mt0 mb5">You need a domain before you can purchase Google Workspace</p>
                <button className="avail-btn" onClick={onAvailableDomainClick}>
                    <span className="off-chip">Flat ₹300 Off</span>
                    View Available Domains
                </button>
                <button className="connect-btn" onClick={onDomainConnectClick}>Connect a Domain</button>
            </section>
        </Modal>
    )
};

export default WorkspaceDomainPurchaseHook;