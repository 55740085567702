export const GST_INFO_SYMBOL = "https://cdn.dotpe.in/longtail/custom_website/4430/nBKBI0YX.png";
export const EDIT_GST_ICON = "https://cdn.dotpe.in/longtail/custom_website/4430/osbADbmM.png";
export const REMOVE_GST_ICON = "https://cdn.dotpe.in/longtail/custom_website/4430/Ig1TJhqB.png";
export const GST_STATES = {
  ADD: 1,
  SAVED: 2,
  ACTIVE: 3,
};

export const VERIFY_GST_API_ACTIONS ={
    REMOVE: 1,
    UPDATE: 2,
    ADD: 3,
};

export const UPDATE_GST_OPTIONS = {
    EDIT: 1,
    REMOVE: 2,
};

export const VAILD_GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
