import React from "react";
import Modal from "./../Modal";
import closeIcon from "./../../Assets/close.png";
import "./style.scss";
import { useSelector } from "react-redux";
import EnvelopeIcon from "../../Assets/envelope.png";
import WhatsAppIcon from "../../Assets/whatsapp-icon-white-filled.svg";
import PhoneIcon from "../../Assets/call.png";
import MailIcon from "../../Assets/mail-icon-solid-white.svg";
import phoneIcon from "../../Assets/phone-call-6.svg";
import { sendDataToApp, trackAppEvents } from "../../Utils/androidEvents";
import {WHATSAPP_CONTACT_US_NUMBER} from "../../constants"

const ContactUsPopUp = (props) => {
  const { help, storeData, store_id } = useSelector((state) => ({
    help: state.appReducer.data.help,
    storeData: state.storeReducer.data.store,
  }));

  // const onContactActions = (type, value) => {
  //   switch (type) {
  //     case "em":
  //       props.onEmail();
  //       break;
  //     case "ph":
  //       props.onPhone(value);
  //       break;
  //     case "wa":
  //       props.onWhatsApp();
  //       break;
  //     default:
  //       return;
  //   }
  // };

  // const imageSelectionForContactType = (type) => {
  //   switch (type) {
  //     case "em":
  //       return EnvelopeIcon;
  //     case "ph":
  //       return PhoneIcon;
  //     case "wa":
  //       return WhatsAppIcon;
  //     default:
  //       return;
  //   }
  // };

  // const ContactButtonComp = ({ option }) => {
  //   return (
  //     <button
  //       className="ContactUsPopUp-popup--btn"
  //       onClick={() => onContactActions(option.id, option.value)}
  //     >
  //       <img src={imageSelectionForContactType(option.id)} />
  //       {option.text}
  //     </button>
  //   );
  // };

  const handlePhone = () => {
    trackAppEvents("Help_Phone", {
      storeid: store_id,
    });
    window.open(`tel:01169039069`, "_self");
  };
  const dotpeContactEmail = () => {
    return storeData?.premium || storeData?.domain_info?.domain_type
      ? `Expert@dotpe.in`
      : `care@dotpe.in`;
  };
  const handleMail = (e) => {
    trackAppEvents("Help_Mail", {
      storeid: store_id,
    });
    window.location.href = `mailto:${dotpeContactEmail()}?subject=Support request for Digital Showroom <Store ID -(${
      window.storeid || store_id || storeData.store_id
        ? window.storeid || store_id || storeData.store_id
        : ""
    })>&body=%28Please do not edit subject line - Digital Showroom %7CStore ID - %3C${
      window.storeid || store_id || storeData.store_id
    }%3E%29%0D%0A%0D%0APlease let us know how we can help%3F+%0D%0AStart writing below %F0%9F%91%87`;
  };
  const handleWhatsapp = () => {
    sendDataToApp({
      shareTextOnWhatsApp: true,
      data: `${storeData?.store_name} Hello
    ${storeData?.domain}`,
      mobileNumber: WHATSAPP_CONTACT_US_NUMBER,
    });
  };

  return (
    <Modal
      visible={props.show}
      className="bottom-modal ContactUsPopUp"
      height={0}
      animation={"slideUp"}
      onClose={props.onClose}
    >
      <span className="modal-close-icon" onClick={props.onClose}>
        <img src={closeIcon} />
      </span>
      <div className="ContactUsPopUp-popup">
        <article className="ContactUsPopUp-popup--title">
          {help?.button?.popup_title || "How do you want to contact us?"}
          {storeData?.premium || storeData?.domain_info?.domain_type ? (
          <>
            <button className="flex care" onClick={handleMail}>
              <img src={MailIcon} className="icon" />
              <span className="text">Email</span>
            </button>
            <button className="flex care" onClick={handlePhone}>
              <img src={phoneIcon} />
              <span className="text">Call</span>
            </button>
            <button className="flex care" onClick={handleWhatsapp}>
              {" "}
              <img src={WhatsAppIcon} className="img" />
              <span className="text">WhatsApp</span>
            </button>
          </>
          ) : (
            <button className="flex care" onClick={handleMail}>
              <img src={MailIcon} />
              <span className="text">Email</span>
            </button>
          )}
        </article>
        {/* <article className="ContactUsPopUp-popup--box">
          {help?.button?.options.map((option) => {
            return <ContactButtonComp option={option} />;
          })}
        </article> */}
      </div>
    </Modal>
  );
};

export default ContactUsPopUp;
