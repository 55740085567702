import React, { useMemo } from "react";
import shopping from "../../Assets/shopping-bag@2x.png";
import arrowf from "../../Assets/arrowf.png";
import "./styles.scss";
import { useSelector } from "react-redux";
import history from "../../Utils/history";
import { CART_CONSTANTS } from "../../Containers/Cart/constants";

export default function FooterCartCount() {
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");
  const token = urlParams.get("token");
  const cartData = useSelector((state) => state.cartReducer.data);
  const { catalog_list } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );

  const cartItems = useMemo(() => {
    if (cartData?.items?.length)
      return cartData.items.filter(
        (item) => item.item_type !== CART_CONSTANTS.ITEM.TYPE_CHARGE
      );
    return [];
  }, [cartData]);

  return cartItems.length > 0 ? (
    <div className="footer-cart-count-wrap">
      <div className="left-wrap">
        <img src={shopping} />
        <span>{cartItems.length}</span>
        <small>{catalog_list?.footer_cart_count_items_text || "Items"}</small>
      </div>
      <div className="right-wrap">
        <button
          onClick={() =>
            history.push(`/cart?storeid=${store_id}&token=${token}`)
          }
        >
          {catalog_list?.footer_cart_count_generate_bill_btn_text ||
            "Generate Bill"}{" "}
          <img src={arrowf} />
        </button>
      </div>
    </div>
  ) : null;
}
