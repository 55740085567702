import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateStoreTheme,
  fetchStoreInfo,
  fetchSubscriptionDetails,
} from "../../Redux/Actions";
import history from "../../Utils/history";
import closeIcon from "./../../Assets/close.png";
import goldTick from "./../../Assets/goldtick.png";
import viewWebsiteIcon from "./../../Assets/eye.png";
import shareWebsiteIcon from "./../../Assets/whatsapp.png";
import ThemeTrailTimer from "./../ThemeTrailTimer";
import goldenComputer from "./../../Assets/goldenDesktop.png";
import golderMobile from "./../../Assets/goldenMobile.png";
import "./style.scss";
import { trackAppEvents, sendDataToApp } from "../../Utils/androidEvents";

const ThemeTrailConfirmationPopUp = (props) => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const storeid = urlParams.get("storeid");
  const token = urlParams.get("token");
  const { subscriptionDetails } = useSelector((state) => state.themesReducer);
  const storeData = useSelector((state) => state.storeReducer.data);
  const [state, setState] = useState({
    endTrailConfirm: false,
  });
  const loaderData = useSelector((state) => state.loaderReducer);
  const hasThemeSubscription = !!storeData?.store?.premium;

  useEffect(() => {
    if (props.show) {
      dispatch(fetchSubscriptionDetails());
    }
    if (props.endThis) {
      setState((state) => ({ ...state, endTrailConfirm: props.endThis }));
    }
  }, [props.show, props.endThis]);

  const viewWebsite = () => {
    trackAppEvents("View_As_Customer", {
      storeid: storeid,
    });
    sendDataToApp({
      redirectBrowser: true,
      data: storeData?.store?.store_info?.store_url,
      redirectURL: storeData?.store?.store_info?.store_url,
    });
  };

  const shareShop = () => {
    trackAppEvents("Premium_StoreShare", {
      storeid: storeid,
      channel: "isTrial",
    });
    // window.ReactNativeWebView &&
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       shareShop: true,
    //     })
    //   );
    if (window.Android) {
      window.location.href = `whatsapp://send?text=${storeData?.store?.store_info?.name}%20is%20now%20Online%20%F0%9F%8F%AA%0AOrder%2024x7%20-%20Click%20on%20the%20link%20to%20place%20an%20order%20${storeData?.store?.store_info?.store_url}%20Pay%20using%20Gpay%2C%20Paytm%2C%20Phonepe%20and%20150%20%20UPI%20Apps%20or%20Cash`;
    } else {
      window.location.href = `https://api.whatsapp.com/send?text=${storeData?.store?.store_info?.name}%20is%20now%20Online%20%F0%9F%8F%AA%0AOrder%2024x7%20-%20Click%20on%20the%20link%20to%20place%20an%20order%20${storeData?.store?.store_info?.store_url}%20Pay%20using%20Gpay%2C%20Paytm%2C%20Phonepe%20and%20150%20%20UPI%20Apps%20or%20Cash`;
    }
  };

  const toggleEnd = () => {
    trackAppEvents("End_Trial_Intent", {
      storeid: storeid,
      themeid: storeData?.store?.theme?.theme_id,
    });
    setState((state) => ({
      ...state,
      endTrailConfirm: !state.endTrailConfirm,
    }));
  };

  const onTrailEndBtnClick = () => {
    trackAppEvents("End_Trial_Theme", {
      storeid: storeid,
      themeid: storeData?.store?.theme?.theme_id,
    });
    dispatch(
      deactivateStoreTheme(storeData?.store?.store_id, () => {
        dispatch(fetchStoreInfo(token));
        toggleEnd();
        history.push(`/theme-explore?token=${token}&storeid=${storeid}`);
      })
    );
  };

  return (
    props.show && (
      <div
        className="themeTrailConfirmPopup"
        onClick={(e) => {
          props.toggleModal();
        }}
      >
        <section
          className="theme-popup"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className="modal-close-icon" onClick={props.toggleModal}>
            <img src={closeIcon} />
          </span>
          {!state.endTrailConfirm ? (
            <>
              <div className="header">
                <article>
                  <img src={goldTick} alt="gold tick icon" />
                </article>
                <article className="trail-text">Theme applied for</article>
              </div>

              <div className="applied--for">
                <article className="" onClick={() => viewWebsite()}>
                  <div className="mobile">
                    <img src={golderMobile} />
                    Mobile
                  </div>
                  <div className="bottom-icon-wrap">
                    <img src={viewWebsiteIcon} />
                    View Website
                  </div>
                </article>
                <article onClick={shareShop}>
                  <div className="desktop">
                    <img src={goldenComputer} />
                    Website
                  </div>
                  <div className="bottom-icon-wrap">
                    <img src={shareWebsiteIcon} />
                    Send link
                  </div>
                </article>
              </div>

              {/* <div className="outside--btnWrapper">
                <button
                  className="view_website--btn"
                  onClick={() => viewWebsite()}
                >
                  <img src={viewWebsiteIcon} />
                  View Website
                </button>
                <button className="share--btn" onClick={shareShop}>
                  <img src={shareWebsiteIcon} />
                  Send link
                </button>
              </div> */}

              <div className="content">
                <article className="expire--text">Trial expires in</article>
                <ThemeTrailTimer />
                <article className="end--trail" onClick={toggleEnd}>
                  End this trial ?
                </article>
              </div>
              <button
                className="buyNow--btn _220jKJ"
                disabled={loaderData.isLoaderVisible}
                onClick={(e) => {
                  e.stopPropagation();
                  props.buyNow();
                }}
              >
                {hasThemeSubscription ? "Apply Now" : "Buy Now"}
              </button>
            </>
          ) : (
            <div className="trail-end">
              <article className="trail-end__text">
                Do you want to end this trial?
              </article>
              <button
                className="trail-end__yes--btn"
                onClick={onTrailEndBtnClick}
              >
                Yes, Try another theme
              </button>
              <button
                className="trail-end__no--btn"
                onClick={props?.endThis ? props.toggleModal : toggleEnd}
              >
                No
              </button>
            </div>
          )}
        </section>
      </div>
    )
  );
};

export default ThemeTrailConfirmationPopUp;
