import { sendDataToApp } from "./androidEvents";
import {
  WORKSPACE_AVAILABLE_GOOGLE_APPS,
  WORKSPACE_USER_STATUS,
} from "./constants";

/**
 * Acts as both, activation link redirection and opening gmail app if already activated
 * @param {*} emailUser
 * @param {*} storeData
 */
export const openWorkspaceEmails = (emailUser, storeData) => {
  const link = emailUser?.is_primary
    ? storeData?.constants?.workspace_primary_email_activation_link
    : storeData?.constants?.workspace_secondary_email_activation_link;
  /** If the user email is not activated open respective URL, else open Gmail app  */
  if (emailUser?.status !== WORKSPACE_USER_STATUS.ACTIVATED)
    sendDataToApp({
      redirectBrowser: true,
      data: link,
      redirectURL: link,
    });
  else {
    if (window.Android) {
      window.Android.sendData(
        JSON.stringify({
          openAppByPackage: true,
          data: WORKSPACE_AVAILABLE_GOOGLE_APPS[0].androidAppLink,
        })
      );
    } else {
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            openAppByPackage: true,
            data: WORKSPACE_AVAILABLE_GOOGLE_APPS[0].iosAppLink,
            id: WORKSPACE_AVAILABLE_GOOGLE_APPS[0]?.iosAppId,
            url: WORKSPACE_AVAILABLE_GOOGLE_APPS[0]?.link,
          })
        );
    }
  }
};
