import React from "react";
import googleMeet from "../../../Assets/google_meet.svg";
import googleChat from "../../../Assets/google_chat.svg";
import googleCalender from "../../../Assets/google_calendar.svg";
import googleMail from "../../../Assets/google_mail.svg";
import googleDocs from "../../../Assets/google_docs.svg";
import googleDrive from "../../../Assets/google_drive.svg";
import googleForms from "../../../Assets/google_forms.svg";
import googleSheet from "../../../Assets/google_sheet.svg";
import googleSlides from "../../../Assets/google_slides.svg";
import './style.scss';

const WorkSpaceFeatureIconList = props => {
    return (
        <section className="workspace-features-wrap relative">

      
        <section className='workspace-features '>
            <div className='item'>
                <img src={googleMail} alt='google meet icon'/>
                { !props.noName && <>Google <br/> Mail</>}
            </div>
            <div className='item'>
                <img src={googleMeet} alt='google meet icon'/>
                { !props.noName && <>Google <br/> Meet</>}
            </div>
            <div className='item'>
                <img src={googleChat} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Chat</>}
            </div>
            <div className='item'>
                <img src={googleCalender} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Calender</>}
            </div>
            <div className='item'>
                <img src={googleDrive} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Drive</>}
            </div>
            <div className='item'>
                <img src={googleDocs} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Docs</>}
            </div>
            <div className='item'>
                <img src={googleSheet} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Sheets</>}
            </div>
            <div className='item'>
                <img src={googleSlides} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Slides</>}
            </div>
            <div className='item'>
                <img src={googleForms} alt='google meet icon'/>
                 { !props.noName && <>Google <br/> Forms</>}
            </div>
        </section>
            <div className="shade absolute h-100 right-0 top-0"></div>
        </section>
    )
}

export default WorkSpaceFeatureIconList;