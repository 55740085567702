import React from "react";
import Modal from "../Modal";
import closeIcon from "./../../Assets/close.png";
import "./style.scss";

const DomainSubTimeSelectionModal = ({
  show,
  price,
  onClose,
  onChange,
  renewalPrice,
  subscriptionTime,
  activeSubscriptionTimeIndex,
}) => {
  return (
    <Modal
      visible={show}
      className="bottom-modal DomainSubTimeSelectionModal"
      height={200}
      animation={"slideUp"}
      onClose={onClose}
    >
      <span className="modal-close-icon" onClick={onClose}>
        <img src={closeIcon} />
      </span>
      <div className="title">Select Duration</div>
      <div className="single-select-radio-groups">
        {subscriptionTime &&
          subscriptionTime?.map((el, ind) => {
            return (
              <article
                className={`radio-item ${
                  ind === activeSubscriptionTimeIndex && "active"
                }`}
                onClick={() => onChange(ind, el)}
                key={`variant-list-modal-${ind}`}
              >
                <div>
                  <input
                    type="radio"
                    name="variant-group"
                    checked={ind === activeSubscriptionTimeIndex}
                  ></input>
                  <span>{el} Year{el !== 1  && 's'}</span>
                </div>

                <div>
                  ₹{ind === 0 ? price : price + (ind) * renewalPrice}
                </div>
              </article>
            );
          })}
      </div>
    </Modal>
  );
};

export default DomainSubTimeSelectionModal;
