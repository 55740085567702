import React from "react";
import BlueInfo from "../../../../Assets/outlined-info-icon-blue.svg";
import "./style.scss";

const PrimaryNotActivatedBanner = ({ usersList }) => {
  return (
    <section className="primary-not-activated-banner">
      <img src={BlueInfo} width="20px" />
      {Array.isArray(usersList) && usersList.length > 0 ? (
        <p className="napoli-blue-color mv0 f14px">
          Please activate your Primary Email account to access rest of accounts.
        </p>
      ) : (
        <p className="napoli-blue-color mv0 f14px">
          Please activate your Primary Email account.
        </p>
      )}
    </section>
  );
};

export default PrimaryNotActivatedBanner;
