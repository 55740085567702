import React from "react";
import "./style.scss";
import GreenTick from "../../../../Assets/outlined-tick-green.svg";

const EmailPurchaseSuccessBanner = (props) => {
  return (
    <div className="email-purchase-success-banner">
      <div>
        <div className="flex items-center mb2">
          <img src={GreenTick} />
          <h3>Great</h3>
        </div>
        <p>{props.emailsCreated} professional emails created</p>
      </div>
      <span className="okay-btn" onClick={props.onClick}>Okay</span>
    </div>
  )
}

export default React.memo(EmailPurchaseSuccessBanner);