import React, { useContext } from "react";
import desktop from "../../Assets/desktop.png";
import crown from "../../Assets/crown.png";
import arrowIcon from "./../../Assets/downarrow.png";
import "./styles.scss";
import { trackAppEvents } from "../../Utils/androidEvents";
import history from "../../Utils/history";
import { ParentContext } from "../../parentProvider";

const PremiumThemeHook1 = () => {
  const contextData = useContext(ParentContext);

  const { additionalParameters, token, storeid } = contextData;

  return (
    <div className="premium-theme-hook flexRowAlign">
      <div className="left-wrap">
        <h3 className="animate__fadeIn animate__animated">
          Get{" "}
          <div>
            Premium
            <img src={crown} alt="" />
          </div>{" "}
          website for your Digital Showroom
        </h3>
        <button
          onClick={() => {
            trackAppEvents("Get_Premium_Website", {
              channel: "domainHistory",
            });
            history.push(`/theme-explore${additionalParameters}`);
          }}
        >
          {" "}
          Explore Now
          <img src={arrowIcon} />
        </button>
      </div>
      <div className="right-wrap">
        <img src={desktop} alt="" />
      </div>
    </div>
  );
};

export default PremiumThemeHook1;
