import React from "react";
import "./style.scss";
import addIcon from "./../../Assets/add-Filled@2x.png";
import lockIcon from "./../../Assets/lock_variant.png";
import { useSelector } from "react-redux";
import { ROUTES } from "../../Utils/constants";

const UnableToFindCta = (props) => {
  const {
    catalog_items,
    totalItems,
    storeData,
    categories,
    localizedTxts,
    featureStatus,
  } = useSelector((state) => ({
    localizedTxts: state.localizationReducer.localizedTxts,
    totalItems: state.catalogReducer.totalItems,
    storeData: state.storeReducer.data,
    categories: state.catalogReducer.categories,
    catalog_items: state.catalogReducer.catalog_items,
    featureStatus: state.commonReducer.featureLocks,
  }));

  const isCatalogBuilderLocked = featureStatus.add_product?.is_locked;

  const goToThemeOffer = () =>
    props.history.push(`${ROUTES.THEME_OFFER}${props.additionalParameters}`);

  const onClick = () =>
    isCatalogBuilderLocked ? goToThemeOffer() : props.onClick();

  return (
    <div className="unabletofind" onClick={onClick}>
      <div className="unabletofind__title">
        {localizedTxts?.unable_to_find_text ||
          "Unable to find item in catalog?"}
        ?
      </div>
      <div className="unabletofind__cta">
        <span>{localizedTxts?.add_new_item_btn_text || "ADD A NEW ITEM"}</span>
        <img src={isCatalogBuilderLocked ? lockIcon : addIcon} />
      </div>
    </div>
  );
};

export default UnableToFindCta;
