import React, { useEffect } from 'react';
import cancelIcon from './../../../Assets/cancel.png';
import './style.scss';

const PaperListItem = props => {

    const listItemRef = React.createRef();

    useEffect(() => {
        listItemRef.current && listItemRef.current.focus();
    },[])

    return (
        <div className="paper-list">
            <input
                ref={listItemRef}
                type="text"
                value={props.name}
                placeholder="Type item name"
                onChange={(e) => props.onHandleItemNameChange(e.target.value)}
            />
            <input
                type="text"
                value={props.quantity}
                placeholder="Qty"
                onChange={(e) => props.onHandleQtyChange(e.target.value)}
            />
             <input
                type="number"
                value={props.price}
                placeholder="Price"
                onChange={(e) => props.onHandlePriceChange(e.target.value)}
            />
            <span
                disabled={!props.name || !props.quantity}
                onClick={() => props.onHandleRemoveItemClicked()}
                className="cross-wrap"
            >
                <img src={cancelIcon} alt="cancel" />
            </span>
        </div>
    );
}

export default PaperListItem;