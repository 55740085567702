import React from "react";
import Modal from "./../Modal";
import closeIcon from "./../../Assets/close.png";
import desktopIcon from "./../../Assets/desktop.png";
import goldTick from "./../../Assets/goldtick.png";
import "./style.scss";
import { useSelector } from "react-redux";

const ThemeTrailModal = (props) => {
  const { loaderData, hasThemeSubscription } = useSelector((state) => ({
    loaderData: state.loaderReducer,
    hasThemeSubscription: !!state.storeReducer.data?.store?.premium,
  }));

  return (
    <Modal
      visible={props.show}
      className="bottom-modal pre-themeTrail-modal"
      height={400}
      animation={"slideUp"}
      onClose={props.toggleModal}
    >
      <span className="modal-close-icon" onClick={props.toggleModal}>
        <img src={closeIcon} />
      </span>
      <section className="title-texts">
        <article>Unable to decide?</article>
        <article>Try Before you Buy</article>
      </section>
      <section className="content">
        <article className="content--text">
          <img src={goldTick} alt="tick icon" />
          60 minutes trial
        </article>
        <article className="content--text">
          <img src={goldTick} alt="tick icon" />
          Take trial unlimited times
        </article>
        <article className="content--img">
          <img src={desktopIcon} alt="design image" />
        </article>
      </section>
      <section className="footer">
        <button
          className="startTrail--btn"
          disabled={loaderData.isLoaderVisible}
          onClick={props.startTrail}
        >
          Start Trial
        </button>
        <button
          disabled={loaderData.isLoaderVisible}
          className="buyNow--btn"
          onClick={props.buyNow}
        >
          {hasThemeSubscription ? (
            "Apply Now"
          ) : (
            <>
              <article>Buy Now</article>
              <article>₹{props.price}</article>
            </>
          )}
        </button>
      </section>
    </Modal>
  );
};

export default ThemeTrailModal;
