import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GmailIcon from "../../../Assets/google_mail.png";
import LineArrow from "../../../Assets/line-arrow-white.svg"
import CopyIcon from "../../../Assets/outlined-copy-icon-blue.svg"
import { copyTextToClipboard } from "../../../Utils/copyToClipboard";
import "./style.scss"
import { openWorkspaceEmails } from "../../../Utils/openWorkspaceEmails";
import EmailPurchaseSuccessBanner from "../Banners/EmailPurchaseSuccessBanner";
import history from "../../../Utils/history";

const PrimaryAccountActivationModule = () => {
  const [successBannerData, setSuccessBannerData] = useState({
    show: false,
    emails: 0
  });

  const { workspacePrimaryUser, storeData } = useSelector(state => ({
    workspacePrimaryUser: state.workspaceReducer.workspacePrimaryUser,
    storeData: state.storeReducer.data.store  
  }));

  useEffect(() => {
    if (history?.location?.state?.showNewlyCreatedEmails) {
      setSuccessBannerData((prevState) => ({
        ...prevState,
        emails: history?.location?.state?.numOfNewlyCreatedEmails,
        show: history?.location?.state?.showNewlyCreatedEmails
      }))
    }
  }, [history?.location?.state]);
  
  const removeSuccessBannerOnClick = () => {
    setSuccessBannerData({
      show: false,
      emails: 0
    })
  }

  return (
    <section className="primary-account-activation-module">
      {/* bannerNewEmail */}
      {successBannerData.show ? (
        <EmailPurchaseSuccessBanner
          emailsCreated={successBannerData.emails}
          onClick={removeSuccessBannerOnClick}
        />
      ) : null}
      {/* primary account activation */}
      <div className="main-component">
        <div className="inner-component">
          <img src={GmailIcon} width="37px" className="mb3" />
          <h2 className="midnight-color mt0 mb2 fw5 f16px">Activate your Email</h2>
          <p className="star-dust-color mv0 fw3 f12px lh-copy">Login at admin.google.com using following credentials and accept Terms of service to activate your account</p>
        </div>
        <div className="inner-component account-info bb">
          <h3>Email ID</h3>
          <p>{workspacePrimaryUser?.email}<img src={CopyIcon} onClick={() => copyTextToClipboard(workspacePrimaryUser?.email)} /></p>
        </div>
        <div className="inner-component account-info bb">
          <h3>Password</h3>
          <p>{workspacePrimaryUser?.password}<img src={CopyIcon} onClick={() => copyTextToClipboard(workspacePrimaryUser?.password)} /></p>
        </div>
        <div className="inner-component account-info">
          <h3>Valid till</h3>
          <p>{moment(workspacePrimaryUser?.expiration_time).format("DD/MM/YYYY")}</p>
        </div>
      </div>
      {/* activation button */}
      <button
        className="bg-napoli-blue-color white f14px"
        onClick={() => openWorkspaceEmails(workspacePrimaryUser, storeData)}
      >
        Activate Now <img src={LineArrow} className="ml1" />
      </button>
    </section>
  );
}

export default PrimaryAccountActivationModule;