import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import "./style.scss";
import { WORKSPACE_INFO_STATUS, WORKSPACE_USER_STATUS } from "../../../Utils/constants";
import ActiveMailImg from "../../../Assets/active-email.svg"
import InactiveMailImg from "../../../Assets/inactive-email.svg"
import InProgressMailImg from "../../../Assets/in-progress-email.svg"
// import ExpiredMailImg from "../../../Assets/error-email.svg"
import Arrow from "../../../Assets/Arrow-Dark.png";
import CopyIcon from "../../../Assets/outlined-copy-icon-blue.svg"
import PrimaryNotActivatedBanner from "../Banners/PrimaryNotActivatedBanner";
import BannerBeforeExpiry from "../Banners/BannerBeforeExpiry";
import BannerAfterExpiry from "../Banners/BannerAfterExpiry";
import { getDaysDiffFromToday, mailToDotpeExpert } from "../../../Utils/common";
import { ToastNotifyError } from "../../Toast";
import { checkForWorkspaceStatusEqualingArgs, checkIfAnyEmailOutOfBounds, workspaceInErrorState } from "../../../Utils/workspaceUtilities";
import { copyTextToClipboard } from "../../../Utils/copyToClipboard";

const EmailListing = (props) => {
  const [nonRenewedUsers, setNonRenewedUsers] = useState([]);

  const { workspaceUsersList, workspacePrimaryUser, workspaceInfo, allWorkspaceUsers, storeData } =
    useSelector((state) => ({
    workspaceUsersList: state.workspaceReducer.workspaceUsersList,
    workspacePrimaryUser: state.workspaceReducer.workspacePrimaryUser,
    workspaceInfo: state.workspaceReducer.workspaceInfo,
    allWorkspaceUsers: state.workspaceReducer.allWorkspaceUsers,
    storeData: state.storeReducer.data.store
  }));

  useEffect(() => {
    if (workspaceUsersList?.length) {
      const renewedUsers = workspaceUsersList.filter((user) => user.status === WORKSPACE_USER_STATUS.STATUS_LICENSE_SCHEDULED_FOR_DELETION)
      setNonRenewedUsers(renewedUsers);
    } else {
      setNonRenewedUsers([]);
    }
  }, [workspaceUsersList]);

  const usersList = useMemo(() => {
    let users = [];
    const associateAccounts = workspaceUsersList?.filter((user) => user.status !== WORKSPACE_USER_STATUS.STATUS_LICENSE_SCHEDULED_FOR_DELETION && user.status !== WORKSPACE_USER_STATUS.PAYMENT_RECEIVED) || [];
    users = workspacePrimaryUser?.status === WORKSPACE_USER_STATUS.SUSPENDED ? associateAccounts : [workspacePrimaryUser, ...associateAccounts];
    return users;
  }, [workspaceUsersList, workspacePrimaryUser]);

  const _renderStatusSpecificImg = useCallback((status) => {
    if (props?.nonRenewedItem) return ActiveMailImg;
    else if (props.inactiveEmail) return InactiveMailImg;
    switch (status) {
      case WORKSPACE_USER_STATUS.ACTIVATED:
        return ActiveMailImg;
      case WORKSPACE_USER_STATUS.EXPIRED:
        return InactiveMailImg;
      case WORKSPACE_USER_STATUS.IN_PROGRESS:
        return InProgressMailImg;
      default:
        return InactiveMailImg;
    }
  }, []);

  
  const ifPrimaryAccSuspended = useMemo(() => workspacePrimaryUser?.status === WORKSPACE_USER_STATUS.SUSPENDED, [workspacePrimaryUser?.status]);

  const areEmailsNonEntity = () => {
    return ifPrimaryAccSuspended ||
      checkForWorkspaceStatusEqualingArgs(WORKSPACE_INFO_STATUS.WORKSPACE_STATUS_EXPIRED) ||
      checkIfAnyEmailOutOfBounds(allWorkspaceUsers)
    }

  const areEmailsExpiring = useMemo(() => {
    return (
      getDaysDiffFromToday(workspaceInfo?.expiration_time) <= 30 &&
      getDaysDiffFromToday(workspaceInfo?.expiration_time) >= 0
    );
  }, [workspaceInfo?.expiration_time]);

  const renderDynamicBanner = (usersList) => {
    if (
      checkIfAnyEmailOutOfBounds(allWorkspaceUsers) ||
      workspaceInErrorState(workspaceInfo)
    ) {
      return (
        <BannerAfterExpiry
          isEmailStatusOutOfBounds={checkIfAnyEmailOutOfBounds(
            allWorkspaceUsers
          )}
        />
      );
    } else if (areEmailsExpiring) {
      return <BannerBeforeExpiry />;
    } else if (ifPrimaryAccSuspended) {
      return <PrimaryNotActivatedBanner usersList={usersList} />;
    }
    return null;
  };

  const userItemClick = (user) => {
    const userStatusInProgress =
      user?.status === WORKSPACE_USER_STATUS.IN_PROGRESS;
    /*
      this if statement is satisfied if user status is 0
    */
    if (userStatusInProgress) {
      ToastNotifyError(
        "Email creation is in progress. Please wait for some time."
      );
      return;
    }
  };

  const renderNonRenewedUsersListing = () => {
    const selectedUser = nonRenewedUsers?.length ? nonRenewedUsers[0] : false;

    return (
      <section className="non-renewed-section">
        {selectedUser ? (
          <p className="renewable-text pt3">
            Following accounts will be deleted on{" "}
            {moment(selectedUser.expiration_time).format("DD/MM/YYYY")}. To
            renew,{" "}
            <span
              onClick={() => mailToDotpeExpert(storeData)}
              className="underline"
            >
              contact us
            </span>
          </p>
        ) : null}
        <section className="email-list">
          {nonRenewedUsers?.map((user) => (
            <EmailItem
              key={user.id}
              inactiveEmail={false}
              user={user}
              primaryAccount={workspacePrimaryUser}
              specificImage={ActiveMailImg}
              areEmailsNonEntity={areEmailsNonEntity}
              userItemClick={(currentUser) => userItemClick(currentUser)}
            />
          ))}
        </section>
      </section>
    );
  };
  return (
    <section className="email-listing">
      <div className="heading mb3">
        <h3>Your Email(s)</h3>
        {workspaceInfo?.expiration_time ? (
          <p>
            Valid till:{" "}
            {moment(workspaceInfo?.expiration_time).format("DD/MM/YYYY")}
          </p>
        ) : null}
      </div>
      {/* banners */}
      {renderDynamicBanner(usersList)}
      {/* email list */}
      {usersList.length ? (
        <div
          className={`email-list ${
            !areEmailsNonEntity ? "inactive-email" : ""
          }`}
        >
          {usersList?.map((user) => (
            <EmailItem
              key={user.id}
              inactiveEmail={props.inactiveEmail}
              user={user}
              statusSpecificImg={(status) => _renderStatusSpecificImg(status)}
              areEmailsNonEntity={areEmailsNonEntity}
              userItemClick={(currentUser) => userItemClick(currentUser)}
              primaryAccount={workspacePrimaryUser}
            />
          ))}
        </div>
      ) : null}
      {renderNonRenewedUsersListing()}
    </section>
  );
};

export const EmailItem = (props) => {
  const [accordionId, setAccordionId] = useState(null);

  const userClick = (e, currentUser) => {
    e.stopPropagation();
    if (!props.inactiveEmail) {
      setAccordionId(accordionId === currentUser.id ? null : currentUser.id);
      props.userItemClick(currentUser);
    }
  };

  const checkForInactiveEmail = () => {
    if (props?.inactiveEmail) return true;
    else if (props?.nonRenewedItem) {
      return false;
    } else {
      return props?.primaryAccount?.status !== WORKSPACE_USER_STATUS.ACTIVATED;
    }
  };

  return (
    <section
      className={`email-list--item w-100 flex justify-between ${
        accordionId === props?.user?.id ? "items-start" : "items-center"
      } ${props?.inactiveEmail ? "default" : "pointer"} ${
        checkForInactiveEmail() ? "o-20" : ""
      }`}
      onClick={(e) => userClick(e, props.user)}
    >
      <section
        className={`flex email-list--item-left w-100 ${
          accordionId === props?.user?.id ? "items-start" : "items-center"
        }`}
      >
        <img
          src={
            props?.specificImage || props.statusSpecificImg(props?.user?.status)
          }
        />
        {accordionId === props.user?.id
          ? allUserData(props?.user)
          : onlyUserEmail(props?.user)}
      </section>
      <img
        src={Arrow}
        className="arrow-carat"
        style={{
          transform: `rotate(${
            accordionId === props?.user?.id ? "180deg" : "0deg"
          })`,
        }}
      />
    </section>
  );
};

const onlyUserEmail = (user) => {
  return (
    <>
      <p
        className="email-value mv0 flex items-center pl2 fw3"
        title={user?.email}
      >
        {user?.email}
      </p>
      {user?.is_primary ? (
        <span className="f14px black fw3 pl1 pr2">(Primary)</span>
      ) : null}
    </>
  );
};

const allUserData = (user) => {
  const copyToClipboard = (event, valueToCopy) => {
    event.stopPropagation();
    copyTextToClipboard(valueToCopy);
  };

  return (
    <div className="ph2 all-user-data">
      <div className="mb24px">
        <span className="item-label star-dust-color">Email:</span>
        <p className="item-value flex items-center">
          {user.email}
          <img
            src={CopyIcon}
            width="14px"
            className="mh2 pointer"
            onClick={(e) => copyToClipboard(e, user.email)}
          />
        </p>
      </div>
      <div>
        <span className="item-label star-dust-color">Temporary Password:</span>
        <p className="item-value flex items-center">
          {user.password}
          <img
            src={CopyIcon}
            width="14px"
            className="mh2 pointer"
            onClick={(e) => copyToClipboard(e, user.password)}
          />
        </p>
      </div>
    </div>
  );
};

export default EmailListing;
