import { LAZYPAY_AUTH_MODAL_VIEWS } from "./../../Utils/constants";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { initLazyPayPayment, verifyLazyPayOtp } from "../../Redux/Actions";
import history from "../../Utils/history";
import layPayImg from "./../../Assets/lazypay_.png";
import closeIcon from "./../../Assets/close.png";
import { CONSTANTS } from "../../Utils/storage";

const LazyPayAuth = (props) => {
  let timerID = null;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    phone: "",
    OTP: "",
    OTPsent: false,
    timer: 59,
    isTimeUp: false,
    showIncorrectOtpErr: false,
  });

  const { lazyPayAuthModal, paymentInfo } = useSelector((state) => ({
    lazyPayAuthModal: state.commonReducer.lazyPayAuthModal,
    paymentInfo: state.paymentReducer.paymentInfo,
  }));

  useEffect(() => {
    if (
      lazyPayAuthModal?.show &&
      lazyPayAuthModal.view === LAZYPAY_AUTH_MODAL_VIEWS.OTP
    ) {
      setState((state) => ({ ...state, OTPsent: true }));
    }
  }, [lazyPayAuthModal?.show]);

  useEffect(() => {
    if (state.OTPsent) {
      startTimer();
    }

    return () => {
      /** Clear the Inteeval if any */
      if (timerID != null) {
        clearInterval(timerID);
      }
    };
  }, [state.OTPsent]);

  // Start the timmer
  const startTimer = () => {
    let seconds = state.timer;
    timerID = setInterval(() => tick(--seconds), 1000);
  };

  // Increase the timing (second) tick
  const tick = (seconds) => {
    let updatedTime;
    if (seconds > 0) {
      updatedTime = (seconds < 10 ? "0" : "") + String(seconds);
      setState((state) => ({
        ...state,
        timer: updatedTime,
      }));
    } else {
      clearInterval(timerID);
      setState((state) => ({
        ...state,
        timer: 59,
        isTimeUp: true,
      }));
    }
  };

  const resendOTP = () => {
    if (!state.isTimeUp) return;

    setState((state) => ({ ...state, isTimeUp: false }));
    dispatch(initLazyPayPayment(paymentInfo));
    let seconds = state.timer;
    timerID = setInterval(() => tick(--seconds), 1000);
  };

  const handleChange = (val) => {
    setState((state) => ({ ...state, OTP: val }));
  };

  const onPayBtnHandle = () => {
    const txnID = sessionStorage.getItem(CONSTANTS.LOCAL.TRANSACTION_ID);
    dispatch(
      verifyLazyPayOtp(
        {
          transactionId: txnID,
          otp: state.OTP,
          requestType: "pay",
        },
        history
      )
    );
  };

  const _renderNotEligibleView = () => {
    return (
      <section>
        <p className="mv0">
          <img
            src={layPayImg}
            loading="lazy"
            alt="lazypay image"
            className="h3"
          />
        </p>
        <p className="f16px lh-copy">
          Currently we don’t have LazyPay method eligible for you. Please select
          another payment method
        </p>
        <button
          className="bg-amaranth-color white w-100 mt3 br4 bn fw6 f16px pv16px"
          onClick={props.onClose}
        >
          CHOOSE ANOTHER METHOD
        </button>
      </section>
    );
  };

  const _renderOTPView = () => {
    return (
      <section>
        <p className="w-60 fw6 f20px lh-copy mt0">
          Enter OTP sent to ‘{paymentInfo?.phone}’ & Pay
        </p>
        <span className="closeicon" onClick={props.onClose}>
          <img loading="lazy" src={closeIcon} alt="cancel" />
        </span>
        <OtpInput
          value={state.OTP}
          onChange={(otp) => handleChange(otp)}
          numInputs={4}
          separator={<span> </span>}
          isInputNum={true}
          containerStyle={{
            display: "grid",
            gridTemplateColumns: "repeat(4,1fr)",
            gridColumnGap: 0,
            width: "fit-content",
            marginTop: 20,
          }}
          shouldAutoFocus={true}
          inputStyle={{
            width: 44,
            height: 44,
            border: "1px solid #707070",
            borderRadius: 4,
            boxShadow: "none",
            outline: "none",
            marginRight: 20,
          }}
        />
        {state.showIncorrectOtpErr && (
          <p className="f14px red">
            Incorrect OTP, entered please enter again.
          </p>
        )}
        <span
          className="timer f14px mb2"
          style={{
            marginTop: "20px",
            display: "block",
          }}
        >
          <label
            className={`fw5 mr2 ${
              state.isTimeUp ? "amaranth-color " : "black-30"
            }`}
            onClick={resendOTP}
          >
            Resend OTP
          </label>
          {!state.isTimeUp && (
            <label className="fw5 o-30">in {`0:${state.timer} s`}</label>
          )}
        </span>
        <button
          className="bg-amaranth-color white w-100 mt3 br4 bn fw6 f16px pv16px mt4"
          onClick={onPayBtnHandle}
        >
          CONFIRM & PAY
        </button>
      </section>
    );
  };

  const _renderEligibleView = () => {
    return (
      <section>
        <p className="mv0">
          <img
            src={layPayImg}
            loading="lazy"
            alt="lazypay image"
            className="h3"
          />
        </p>
        <p className="f20px fw6">Confirm your payment with LazyPay</p>
        <p className="f16px fw6">₹{paymentInfo?.amount}</p>
        <button
          className="bg-amaranth-color white w-100 mt3 br4 bn fw6 f16px pv16px"
          onClick={props.onClose}
        >
          CONFIRM & PAY
        </button>
      </section>
    );
  };

  const _renderView = (type) => {
    switch (type) {
      case LAZYPAY_AUTH_MODAL_VIEWS.OTP:
        return _renderOTPView();
      case LAZYPAY_AUTH_MODAL_VIEWS.ELIGIBLE:
        return _renderEligibleView();
      case LAZYPAY_AUTH_MODAL_VIEWS.NONELIGIBLE:
        return _renderNotEligibleView();
      default:
        return <></>;
    }
  };

  return _renderView(lazyPayAuthModal?.view);
};

export default LazyPayAuth;
