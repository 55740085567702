import React from "react";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import paymentstrip from "../../Assets/paymentstrip.png";
import { addToCart } from "../../Redux/Actions";
import { ToastNotifyError } from "./../Toast";
import { sendDataToApp, trackAppEvents } from "../../Utils/androidEvents";
import { getCartItemsWithGst, isStoreTypeB2b } from "../../Utils/common";
import { getUrlParams } from "../../Utils/getUrlParams";

export default function FooterSendBill(props) {
  const isLoaderVisible = useSelector(
    (state) => state.loaderReducer.isLoaderVisible
  );
  const isB2bStore = isStoreTypeB2b();
  const [totalAmount, handleAmount] = React.useState(0);
  const [b2bOrderId] = getUrlParams(["b2bOrderId"]);
  const [name] = getUrlParams(["name"]);
  const [phone] = getUrlParams(["phone"]);
  const dispatch = useDispatch();
  const { cart } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );
  const { cartData, storeData } = useSelector((state) => ({
    cartData: state.cartReducer.data,
    storeData: state.storeReducer.data.store,
  }));

  const calculateOriginalAmount = () => {
    let originalAmount = 0;
    cartData && cartData.items && cartData.items.length > 0
      ? cartData.items.map((item) => {
          if (item.item_quantity && item.price && item.item_type !== "charge") {
            originalAmount = originalAmount + item.item_quantity * item.price;
          } else if (item.item_type === "charge") {
            if (item.item_name === "Delivery") {
              originalAmount = originalAmount + item.price;
            } else if (item.item_name === "Discount") {
              originalAmount = originalAmount + 0;
            } else {
              originalAmount =
                item.price >= 0 ? originalAmount + item.price : originalAmount;
            }
          }
        })
      : (originalAmount = cartData.amount);
    return originalAmount;
  };

  const parseCartItems = (tempData) => {
    const data = [];
    tempData.items.map(
      (item) => {
        if (item.item_type !== "charge") {
          const newItem = {
            ...item,
            actual_price: item.item_price,
            amount: item.item_quantity * item.discounted_price,
            quantity: Number(item.item_quantity),
            item_quantity: String(item.item_quantity),
          };
          data.push(newItem);
        }
      },
    );
    return data;
  };

  const parseCartItemsForAdditionalInfo = (tempData) => {
    const data = tempData.items.reduce(
      (acc, curr) => {
        if (curr.item_type === "charge" && curr.item_name === "Delivery") {
          acc.delivery_charge = curr.discounted_price;
        } else if (
          curr.item_type === "charge" &&
          curr.item_name === "Discount"
        ) {
          acc.discount = curr.discounted_price;
        } else if (
          curr.item_type === "charge" &&
          curr.item_name !== "Delivery" &&
          curr.item_name !== "Discount"
        ) {
          acc.extra_charges = curr.discounted_price;
          acc.extra_charge_name = curr.item_name;
        }
        return acc;
      },
      {
        extra_charges: null,
        extra_charge_name: null,
        discount: null,
        delivery_charge: null,
      }
    );
    return data;
  };

  const handleCreateOrder = (updatedCartData) => {
    const extraValues = parseCartItemsForAdditionalInfo(updatedCartData);
    const parsedCartItems = parseCartItems(updatedCartData);
    const cartItemsWithGst = getCartItemsWithGst(parsedCartItems);
    const newCartData = {
      ...updatedCartData,
      items: cartItemsWithGst,
      ...extraValues,
      name: name,
      phone: phone,
      store_id: Number(storeData.store_id),
      order_id: Number(b2bOrderId),
      order_type: b2bOrderId ? 0 : 3,
      order_mode: 3,
    };
    return newCartData;
  };

  const handleSendBill = () => {
    if (
      !cartData.items.every(
        (el) => el.item_name && (el.item_price || el.item_price === 0)
      )
    ) {
      ToastNotifyError("Name and Price both should be filled!");
      return;
    }
    if (isB2bStore) {
      sendDataToApp({
        sendPaymentLink: true,
        orderDetails: handleCreateOrder({ ...cartData, pay_amount: totalAmount + (props.totalGstAmount || 0), amount: totalAmount + (props.totalGstAmount || 0), gst_amount: props.totalGstAmount || 0 }),
      });
      return;
    }
    trackAppEvents("Generate Self Bill", {
      isWebiew: true,
    });

    let originalAmount = calculateOriginalAmount();

    dispatch(
      addToCart({
        ...cartData,
        pay_amount: totalAmount + (props.totalGstAmount || 0),
        amount: originalAmount + (props.totalGstAmount || 0),
        gst_amount: props.totalGstAmount || 0,
      })
    );
    props.handleSendBill();
  };

  React.useEffect(() => {
    let totalSum = 0;
    cartData && cartData.items && cartData.items.length > 0
      ? cartData.items.map((item) => {
          if (item.variants_selected && item.variants_selected.length > 0) {
            item.variants_selected.map((variant) => {
              totalSum = calculateAmount(
                totalSum,
                variant,
                variant.quantity,
                item.item_type,
                variant.name,
                "variant"
              );
            });
          } else {
            totalSum = calculateAmount(
              totalSum,
              item,
              item.item_quantity,
              item.item_type,
              item.item_name
            );
          }
        })
      : (totalSum = cartData.amount);
    handleAmount(parseFloat(totalSum));
  }, [cartData]);

  const calculateAmount = (
    totalSum,
    item,
    item_quantity,
    item_type,
    item_name,
    type
  ) => {
    if (item_quantity && item.price && item_type !== "charge") {
      totalSum = totalSum + item_quantity * item.discounted_price;
      if (type === "variant")
        item.amount = item_quantity * item.discounted_price;
    } else {
      if (item_name === "Discount") {
        totalSum = totalSum - item_quantity * item.discounted_price;
      } else {
        totalSum = totalSum + item_quantity * item.discounted_price;
      }
    }
    return totalSum;
  };

  const handleChange = (val) => {
    handleAmount(parseFloat(val));
  };

  const disableSendBillBtn = () => {
    let shouldDisableSendBillBtn = false;
    cartData.items.forEach((item) => {
      if (Array.isArray(item.variants_selected) && !!item.variants_selected?.length) {
        // eslint-disable-next-line no-unused-expressions
        item?.variants_selected.forEach((variant) => {
          if (!variant?.quantity || !variant?.discounted_price || (variant?.managed_inventory && (!variant?.available || variant?.quantity > variant?.available_quantity))) {
            shouldDisableSendBillBtn = true;
          }
        });
      } else {
        if (!item?.item_quantity || !item?.discounted_price || (item?.managed_inventory && (!item?.available || item?.item_quantity > item?.available_quantity))) {
          shouldDisableSendBillBtn = true;
        }
      }
    });
    if (shouldDisableSendBillBtn || !totalAmount || totalAmount <= 0 || isLoaderVisible) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="footer-sendbill-wrap">
      <label className="heading">
        {(cart && cart["footer_send_bill_&_text"]) || "SEND BILL &"}{" "}
        <span>{cart?.footer_get_money_text || "get money"} </span>
      </label>
      <div className="input-wrap">
        <span className="rupee-icon">₹</span>
        <input
          maxLength={10}
          value={(totalAmount + props.totalGstAmount || 0).toFixed(2) || ""}
          type="numeric"
          placeholder={
            cart?.footer_placeholder_enter_amount_Text || "Enter Amount"
          }
          disabled={!!props?.isFromCart}
          pattern="\d*"
          onChange={(e) => handleChange(e.target.value)}
        />
        <button
          disabled={disableSendBillBtn()}
          onClick={() => handleSendBill()}
        >
          {isLoaderVisible ? "Loading.." : cart?.send_bill_text || "Send Bill"}
        </button>
      </div>
      <div className="footer-strip">
        <label>
          {(cart && cart["&_customer_can_pay_via_text"]) ||
            "& customer can pay via…"}
        </label>
        <img src={paymentstrip} />
      </div>
    </div>
  );
}
