/**
 * This is the flat list component
 * Use cases : Used for the auto scroll pagination lists
 */
import React, { useCallback, useRef } from "react";

const FlatList = ({
  isLoaderVisible,
  onScrollEnd,
  isNext,
  LoaderComponent,
  loaderComponentCount = 3,
  renderList,
}) => {
  const observer = useRef();

  const lastStoreElementRef = useCallback(
    (node) => {
      if (isLoaderVisible) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollEnd();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoaderVisible, onScrollEnd]
  );

  return (
    <section>
      {/* List */}
      {renderList()}

      {/* Loader component */}
      {isLoaderVisible && (
        <>
          {new Array(loaderComponentCount).fill(0).map((el) => (
            <LoaderComponent />
          ))}
        </>
      )}

      {/* On Scroll end div */}
      {isNext === true && (
        <div className="show-more" ref={lastStoreElementRef}>
          {LoaderComponent || "show more"}
        </div>
      )}
    </section>
  );
};

export default FlatList;
