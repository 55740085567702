import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import {
  addToCart,
  fetchCatalogItemById,
  saveDetailedItemById,
  saveProductLoaderId,
  setChooseAndRepeatModalVisibility,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
} from "../../Redux/Actions";
import add from "../../Assets/add.png";
import minus from "../../Assets/minus.png";
import optionsMultiple from "../../Assets/optionsMultiple.png";
import { mutateVariantItemWithCartData } from "../../Utils/common";
import { ACTION_TYPES, ITEM_TYPE } from "./constants";
import { ToastNotifyInfo } from "../Toast";
import { isStoreTypeB2b } from "../../Utils/common";
import InfoErrorSvg from "../../Assets/infoErrorSvg.svg";
import { useDebounce } from "../../Hooks/useDebounce";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { CURRENCY_SYMBOL } from "../../Config";

export default function ProductItem(props) {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartReducer.data);
  const [cartItem, handleCartItem] = React.useState({});
  const [insufficientInventoryError, setInsufficientInventoryError] =
    useState(false);
  const [b2bItemPrice, setB2bItemPrice] = useState("");
  const [b2bItemQuantity, setB2bItemQuantity] = useState("");
  const debouncedHandleB2bItemQuantityChange = useDebounce(
    handleB2bItemQuantityChange,
    700
  );
  const debouncedHandleB2bItemPriceChange = useDebounce(
    handleB2bItemPriceChange,
    700
  );
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");
  const isB2bStore = isStoreTypeB2b();

  useEffect(() => {
    if (props.variant) {
      setB2bItemQuantity(props.variant.quantity);
      setB2bItemPrice(props.variant.discounted_price);
    } else {
      setB2bItemQuantity(cartItem.item_quantity);
      setB2bItemPrice(props.data.discounted_price);
    }
  }, [props?.variant, cartItem]);

  useEffect(() => {
    const data = cartData.items.find((x) => x.id === props.data.id);
    if (data && data?.id === props.data.id) {
      handleCartItem(data);
    } else {
      handleCartItem({});
    }
  }, [cartData]);

  useEffect(() => {
    if (
      props.variant &&
      props.variant.managed_inventory &&
      (props.variant.quantity > props.variant.available_quantity ||
        !props.variant.available)
    ) {
      setInsufficientInventoryError(true);
    } else if (
      !props.variant &&
      cartItem.managed_inventory &&
      (cartItem.item_quantity > cartItem?.available_quantity ||
        !cartItem.available)
    ) {
      setInsufficientInventoryError(true);
    } else {
      setInsufficientInventoryError(false);
    }
  }, [cartData]);

  const handleAddRemoveItem = (type) => {
    /** if item is out of stock then it is not added in the cart of send bill component */
    if (isAddButtonDisabled) {
      ToastNotifyInfo("Item is out of stock!");
      return;
    }
    /** If inventory management is ON & the item has already reached limit them return the fucntion at the top */
    if (
      type === ACTION_TYPES.ADD &&
      cartItem?.managed_inventory === 1 &&
      cartItem?.item_quantity === cartItem?.available_quantity &&
      !cartItem?.variants_selected?.length
    ) {
      return;
    }
    if (props.variant && props.isCartPage) {
      const newCart = mutateVariantItemWithCartData(
        type,
        props.data,
        props.variant,
        cartData.items
      );
      dispatch(addToCart({ items: newCart }));
      return;
    }

    if (props.data.variants_count > 0) {
      dispatch(saveProductLoaderId(props.data.id));

      /** find the item index in cart */
      const itemIdxInCart = cartData.items.findIndex(
        (el) => el.id === props.data.id
      );
      if (itemIdxInCart !== -1) {
        switch (type) {
          case ACTION_TYPES.ADD:
            dispatch(setChooseAndRepeatModalVisibility(true));
            break;
          case ACTION_TYPES.REMOVE:
            dispatch(setSelectedVariantModalVisibility(true));
            break;
        }
      } else {
        dispatch(
          fetchCatalogItemById(
            { store_id: store_id, item_id: props.data.id },
            true
          )
        );
      }
    } else {
      let newState = { ...cartData };
      let cartItemObj = {}; //to add the item object
      let productIndex = cartData.items.findIndex(
        (x) => x.id === props.data.id
      ); //find the item index and update quantity
      if (cartItem.item_quantity > 0) {
        //item is already present
        cartItemObj = {
          ...props.data,
          item_id: props.data.id,
          item_name: props.data.name,
          item_type: ITEM_TYPE.CATALOG,
          item_price: isB2bStore ? "" : props.data.price,
          price: isB2bStore ? "" : props.data.price,
          discounted_price: isB2bStore ? "" : props.data.discounted_price,
          item_quantity:
            type === ACTION_TYPES.ADD
              ? cartItem.item_quantity + 1
              : cartItem.item_quantity - 1,
        };
        if (cartItem.item_quantity === 1 && type === ACTION_TYPES.REMOVE) {
          // in case of 0 qty remove the item from cart
          newState.items.splice(productIndex, 1);
          handleCartItem({});
        } else {
          //if qty > 1 just pass it
          newState.items[productIndex] = cartItemObj;
        }
      } else {
        //item no present before in cart
        cartItemObj = {
          ...props.data,
          item_id: props.data.id,
          item_name: props.data.name,
          item_quantity: 1,
          item_type: ITEM_TYPE.CATALOG,
          item_price: isB2bStore ? "" : props.data.price,
          price: isB2bStore ? "" : props.data.price,
          discounted_price: isB2bStore ? "" : props.data.discounted_price,
        };
        newState.items.push(cartItemObj);
      }
      dispatch(addToCart(newState));
    }
  };

  const renderNormalPrice = () => {
    if (props.variant) {
      return props.isCartPage
        ? props.variant.price * Number(props.variant.quantity)
        : props.variant.price;
    }

    return props.isCartPage
      ? props.data.price * props.data.item_quantity
      : props.data.price;
  };

  const renderDiscountPrice = () => {
    if (props.variant) {
      return props.isCartPage
        ? props.variant.discounted_price * Number(props.variant.quantity)
        : props.variant.discounted_price;
    }

    return props.isCartPage
      ? props.data.discounted_price * props.data.item_quantity
      : props.data.discounted_price;
  };

  const isAddButtonDisabled = useMemo(() => {
    const cartItems = cartData.items || [];
    const addedItem =
      cartItems.find((item) => item.id === props.data.id) || props.data;

    return !!(
      !addedItem?.available ||
      (addedItem?.managed_inventory &&
        addedItem?.variants_count === 0 &&
        (addedItem?.available_quantity <= 0 ||
          (addedItem?.item_quantity &&
            addedItem?.item_quantity >= addedItem?.available_quantity)))
    );
  }, [props.data, cartData]);

  const renderATCButton = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={props?.data?.image_url} className="item-thumbnail" />
        {cartItem && cartItem?.item_quantity > 0 ? (
          <>
            <button className="atc-btn">
              <span onClick={() => handleAddRemoveItem(ACTION_TYPES.REMOVE)}>
                {" "}
                <img src={minus} alt="" />{" "}
              </span>
              <small>
                {" "}
                {props.variant
                  ? props.variant.quantity
                  : cartItem.item_quantity}
              </small>
              <span
                onClick={() => handleAddRemoveItem(ACTION_TYPES.ADD)}
                // add gray tint to the icon to show it's disabled state when the particular item in cart has reached it's inventory limit
                className={
                  cartItem?.managed_inventory === 1 &&
                  cartItem?.item_quantity === cartItem?.available_quantity
                    ? "disabled"
                    : ""
                }
              >
                {" "}
                <img src={add} alt="" />{" "}
              </span>
            </button>
            {!props.isCartPage && props.data.variants_count > 0 && (
              <div className="variant-section">
                <img src={optionsMultiple} alt="Options Multiple Icon" />
                {props.data.variants_count || 0} options
              </div>
            )}
          </>
        ) : (
          <>
            <button
              className={`atc-btn ${isAddButtonDisabled ? "disable-btn" : ""}`}
              onClick={() => handleAddRemoveItem()}
            >
              <p>ADD +</p>
            </button>
            {!props.isCartPage && props.data.variants_count > 0 && (
              <div className="variant-section">
                <img src={optionsMultiple} alt="Options Multiple Icon" />
                {props.data.variants_count || 0} options
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderPrice = (data) => {
    return (
      <>
        {data.discounted_price < data.price ? (
          <>
            <span className="discounted-price">
              {"₹"}
              {renderNormalPrice()}
            </span>{" "}
            {"₹"}
            {renderDiscountPrice()}
          </>
        ) : (
          "₹" + renderNormalPrice()
        )}{" "}
      </>
    );
  };

  const renderProductPrice = () => {
    if (isB2bStore) return null;
    return (
      <p
        className="price"
        style={
          props.isCartPage
            ? {
                margin: "7px 0 0 ",
              }
            : null
        }
      >
        {props.variant ? renderPrice(props.variant) : renderPrice(props.data)}{" "}
      </p>
    );
  };

  function containsOnlyNumbers(str) {
    if (str === "") {
      return true;
    }
    return /^\d+$/.test(str);
  }

  const checkForAvailableQuantity = (productData, itemQuantity) => {
    return (
      !productData?.available ||
      (itemQuantity > productData?.available_quantity &&
        productData?.available_quantity)
    );
  };

  const handleB2bItemOutOfStock = (productData, itemQuantity) => {
    if (
      productData?.managed_inventory &&
      checkForAvailableQuantity(productData, itemQuantity)
    ) {
      setInsufficientInventoryError(true);
    }
  };

  const handleB2bVariantOutOfStock = (item, variant, newVariantQuantity) => {
    if (
      variant?.managed_inventory &&
      checkForAvailableQuantity(variant, newVariantQuantity)
    ) {
      setInsufficientInventoryError(true);
    }
  };

  function handleB2bItemQuantityChange() {
    if (props.variant) {
      const tempCart = [...JSON.parse(JSON.stringify(cartData.items))];
      let item = {};

      // create important keys in the Item
      item = {
        ...props.data,
        item_name: props.data.name,
        item_price: props.data.price,
        unit_price: props.data.discounted_price,
        discounted_price: props.data.discounted_price,
        item_id: props.data.id,
        item_type: "catalog",
      };

      const idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);
      const variantIdx = tempCart[idx].variants_selected.findIndex(
        (el) => el.variant_id === props.variant.variant_id
      );

      tempCart[idx].variants_selected[variantIdx].quantity =
        Number(b2bItemQuantity);

      // calculate amount of variant selected
      // amount -> quantity * discounted_price
      tempCart[idx].variants_selected[variantIdx].amount =
        Number(tempCart[idx].variants_selected[variantIdx].quantity) *
        Number(tempCart[idx].variants_selected[variantIdx].discounted_price);
      let itemQuantityWithoutCurrentVariant = 0;
      tempCart[idx].variants_selected.forEach((variant) => {
        if (
          variant.variant_id !==
          tempCart[idx].variants_selected[variantIdx].variant_id
        ) {
          itemQuantityWithoutCurrentVariant =
            itemQuantityWithoutCurrentVariant + variant.quantity;
        }
      });
      tempCart[idx].item_quantity =
        Number(itemQuantityWithoutCurrentVariant) + Number(b2bItemQuantity);
      tempCart[idx].amount =
        Number(tempCart[idx].quantity) * Number(tempCart[idx].discounted_price);
      handleB2bVariantOutOfStock(
        item,
        tempCart[idx].variants_selected[variantIdx],
        Number(b2bItemQuantity)
      );
      dispatch(addToCart({ ...cartData, items: tempCart }));
    } else {
      const newState = { ...cartData };
      let cartItemObj = {}; // to add the item object
      const productIndex = cartData.items.findIndex(
        (x) => x.id === props.data.id
      ); // find the item index and update quantity
      handleB2bItemOutOfStock(cartItem, Number(b2bItemQuantity));
      cartItemObj = {
        ...props.data,
        item_id: props.data.id,
        item_name: props.data.name,
        item_type: "catalog",
        item_price: props.data.price,
        item_quantity: Number(b2bItemQuantity),
      };
      newState.items[productIndex] = cartItemObj;
      dispatch(addToCart(newState));
    }
  }

  function handleB2bItemPriceChange() {
    if (props.variant) {
      const tempCart = [...JSON.parse(JSON.stringify(cartData.items))];
      let item = {};

      // create important keys in the Item
      item = {
        ...props.data,
        item_name: props.data.name,
        item_price: props.data.price,
        unit_price: props.data.discounted_price,
        discounted_price: props.data.discounted_price,
        item_id: props.data.id,
        item_type: "catalog",
      };

      const idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);
      const variantIdx = tempCart[idx].variants_selected.findIndex(
        (el) => el.variant_id === props.variant.variant_id
      );
      tempCart[idx].variants_selected[variantIdx].item_price =
        Number(b2bItemPrice);
      tempCart[idx].variants_selected[variantIdx].discounted_price =
        Number(b2bItemPrice);
      tempCart[idx].variants_selected[variantIdx].price = Number(b2bItemPrice);
      tempCart[idx].variants_selected[variantIdx].amount =
        Number(tempCart[idx].variants_selected[variantIdx].quantity) *
        Number(tempCart[idx].variants_selected[variantIdx].discounted_price);
      let itemPriceWithoutCurrentVariant = 0;
      tempCart[idx].variants_selected.forEach((variant) => {
        if (
          variant.variant_id !==
          tempCart[idx].variants_selected[variantIdx].variant_id
        ) {
          itemPriceWithoutCurrentVariant =
            itemPriceWithoutCurrentVariant + Number(variant.discounted_price);
        }
      });
      tempCart[idx].item_price =
        itemPriceWithoutCurrentVariant + Number(b2bItemPrice);
      tempCart[idx].discounted_price =
        itemPriceWithoutCurrentVariant + Number(b2bItemPrice);
      tempCart[idx].price =
        itemPriceWithoutCurrentVariant + Number(b2bItemPrice);
      tempCart[idx].amount =
        Number(tempCart[idx].quantity) * Number(tempCart[idx].discounted_price);
      dispatch(addToCart({ ...cartData, items: tempCart }));
    } else {
      const newState = { ...cartData };
      let cartItemObj = {}; // to add the item object
      const productIndex = cartData.items.findIndex(
        (x) => x.id === props.data.id
      ); // find the item index and update quantity
      cartItemObj = {
        ...props.data,
        item_id: props.data.id,
        item_name: props.data.name,
        item_type: "catalog",
        item_price: Number(b2bItemPrice),
        price: Number(b2bItemPrice),
        discounted_price: Number(b2bItemPrice),
      };
      newState.items[productIndex] = cartItemObj;
      dispatch(addToCart(newState));
    }
  }

  const handleRemoveB2bItem = () => {
    const newState = { ...cartData };
    if (props.variant) {
      const itemAlreadyExistsInCartIndex = newState.items.findIndex(
        (x) => x.id === props.data.id
      );
      const filteredVariantItems = newState.items[
        itemAlreadyExistsInCartIndex
      ]?.variants_selected?.filter(
        (variant) => variant?.variant_id !== props.variant.variant_id
      );
      if (filteredVariantItems.length) {
        newState.items[itemAlreadyExistsInCartIndex].variants_selected =
          filteredVariantItems;
      } else {
        const filteredB2bCartItems = newState.items.filter(
          (x) => x.id !== props.data.id
        );
        newState.items = filteredB2bCartItems;
      }
      handleCartItem({});
      dispatch(addToCart(newState));
    } else {
      const filteredB2bCartItems = newState.items.filter(
        (x) => x.id !== props.data.id
      );
      newState.items = filteredB2bCartItems;
      handleCartItem({});
      dispatch(addToCart(newState));
    }
  };

  const getInsufficientInventoryErrorText = () => {
    if (props.variant) {
      return !props.variant?.available
        ? "Item is out of stock"
        : `Insufficient inventory, add a number below ${
            Number(props.variant?.available_quantity) + 1
          }`;
    }
    return !cartItem?.available
      ? "Item is out of stock"
      : `Insufficient inventory, add a number below ${
          Number(cartItem?.available_quantity) + 1
        }`;
  };

  const renderB2bStoreSpecificInputFields = () => {
    return (
      <div className="flex flex-column">
        <div className="flex">
          <div
            style={{ width: 170, paddingRight: 4 }}
            className="flex item-center"
          >
            <div className="flex flex-column">
              <img
                src={props?.data?.image_url}
                width="40px"
                height="40px"
                style={{ borderRadius: 4 }}
              />
              <p
                onClick={handleRemoveB2bItem}
                style={{
                  color: "#EB5757",
                  fontSize: "10px",
                  fontWeight: 400,
                  paddingTop: "4px",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                Remove
              </p>
            </div>
            <div className="flex flex-column" style={{ paddingLeft: 12 }}>
              <p className="mv0 f5 fw4">
                {props?.data?.name || props?.data?.item_name}
              </p>
              {props.variant && (
                <p className="mv0 f6 gray fw4">
                  {props?.variant?.variant_name}
                </p>
              )}
            </div>
          </div>
          <div style={{ width: 73, marginRight: 12 }}>
            <TextField
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              value={b2bItemQuantity === 0 ? "" : b2bItemQuantity}
              error={insufficientInventoryError}
              onChange={(e) => {
                if (
                  Number(e.target.value) <= 99999 &&
                  containsOnlyNumbers(e.target.value)
                ) {
                  debouncedHandleB2bItemQuantityChange();
                  setB2bItemQuantity(Number(e.target.value));
                }
              }}
            />
          </div>
          <div style={{ width: 75 }}>
            <TextField
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              value={b2bItemPrice === 0 ? "" : b2bItemPrice}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: 17, paddingTop: 1 }}>
                      {CURRENCY_SYMBOL}
                    </span>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                if (
                  Number(e.target.value) <= 100000000 &&
                  containsOnlyNumbers(e.target.value)
                ) {
                  debouncedHandleB2bItemPriceChange();
                  setB2bItemPrice(Number(e.target.value));
                }
              }}
            />
          </div>
        </div>
        {insufficientInventoryError ? (
          <div
            className="flex items-center"
            style={{ color: "#EB5757", paddingTop: 12 }}
          >
            <img src={InfoErrorSvg} alt="Error" />
            <p
              style={{
                fontSize: 12,
                margin: 0,
                fontWeight: 400,
                paddingLeft: 8,
              }}
            >
              {getInsufficientInventoryErrorText()}
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className="product-item-container"
      style={
        props.isCartPage
          ? {
              margin: "10px 0",
              alignItems: "flex-start",
            }
          : null
      }
    >
      {isB2bStore && props.isCartPage ? (
        renderB2bStoreSpecificInputFields()
      ) : (
        <>
          <div
            className="left-wrap"
            style={{
              border: props.noBorder ? 0 : null,
              alignItems: props.isCartPage ? "flex-start" : "center",
            }}
          >
            <div>
              <label>{props.data.name}</label>
              {props.isCartPage && props.variant && (
                <p className="variant-name-item">
                  {props.variant.variant_name}
                </p>
              )}
            </div>
            {props.isCartPage ? renderATCButton() : renderProductPrice()}
          </div>
          <div className="right-wrap">
            {props.isCartPage ? renderProductPrice() : renderATCButton()}
          </div>
        </>
      )}
    </div>
  );
}
