import React, { useContext } from "react";
import "./style.scss";
import RedInfo from "../../../../Assets/outlined-info-icon-red.svg";
import { useSelector, useDispatch } from "react-redux";
import { getDaysDiffFromToday } from "../../../../Utils/common";
import { getWorkspaceRenewalDetails } from "../../../../Redux/Actions";
import history from "../../../../Utils/history";
import { ParentContext } from "../../../../parentProvider";

const BannerBeforeExpiry = () => {
  const dispatch = useDispatch();
  const contextData = useContext(ParentContext);
  const { additionalParameters } = contextData;
  const { workspaceInfo } = useSelector((state) => ({
    workspaceInfo: state.workspaceReducer.workspaceInfo
  }));

  const renewalBtnCallback = (status) => {
    if (status) {
      history.push(`/workspace-renewal${additionalParameters}`)
    }
  };

  const renewAccOnClick = () => {
    dispatch(getWorkspaceRenewalDetails(workspaceInfo?.domain, renewalBtnCallback));
  };
  
  return (
    <section className="banner-before-expiry">
      <h3 className="flex items-center">
        <img src={RedInfo} width="20px" />
        Alert!
      </h3>
      <p>
        Your Professional Email(s) will expire in {getDaysDiffFromToday(workspaceInfo?.expiration_time)} days
      </p>
      <div className="flex justify-end">
        <button className="renew-btn br-pill white" onClick={renewAccOnClick}>Renew Now</button>
      </div>
    </section>
  );
};

export default React.memo(BannerBeforeExpiry);