import { useEffect, useRef, useState } from "react";
import { ToastNotifySuccess } from "../Components/Toast";

/**
 * Provided with a expiry time you can start a timer
 */
export const useTimer = ({ expiryDate, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hour: "00",
    minute: "00",
    second: "00",
  });

  let x = null;
  let timeDifference = null;

  useEffect(() => {
    expiryDate !== "" && _timer();
    return () => {
      clearInterval(x);
      x = null;
      while (x !== null) {
        x = null;
      }
    };
  }, [expiryDate]);

  const _timer = () => {
    clearInterval(x);
    const countDownDate = new Date(
      expiryDate ? expiryDate.replace(" ", "T") : ""
    ).getTime();

    x = setInterval(() => {
      const now = new Date().getTime();
      timeDifference = countDownDate - now;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeLeft({
        days: days > 0 ? days : "00",
        hour: hours > 0 ? hours : "00",
        minute: minutes < 10 ? "0" + minutes : minutes,
        second: seconds < 10 ? "0" + seconds : seconds,
      });

      if (timeDifference <= 0) {
        clearInterval(x);
        setTimeLeft({
          days: "00",
          hour: "00",
          minute: "00",
          second: "00",
        });
        onTimerEnd && onTimerEnd();
      }
    }, 1000);
  };

  return {
    days: timeLeft.days || "00",
    hours: timeLeft.hour || "00",
    minutes: timeLeft.minute || "00",
    seconds: timeLeft.second || "00",
    timeDifference,
  };
};
