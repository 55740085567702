import { useEffect } from "react";
import { isJson } from "../Utils/isJson";

const useAppMessageListener = (onReceiveMessageFromApp) => {
  useEffect(() => {
    function onMessageReceived(message) {
      let data = message?.data || {};
      if (isJson(message?.data)) data = JSON.parse(message.data);
      onReceiveMessageFromApp && onReceiveMessageFromApp(data);
    }

    window.addEventListener("message", onMessageReceived);
    return () => {
      window.removeEventListener("message", onMessageReceived);
    };
  }, []);

  return null;
};

export default useAppMessageListener;
