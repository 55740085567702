import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import closeIcon from "./../../Assets/close.png";
import "./style.scss";
import Input from "../Input";
import { useDispatch, useSelector } from "react-redux";
import { addCatalogItem, setAddProductModalVisible } from "../../Redux/Actions";
import { ToastNotifyError } from "../Toast";

const CreativeEditPopUp = (props) => {
  const html_defaults = props.data;
  const { creative_edit } = useSelector((state) => state.localizationReducer.localizedTxts);


  const [formData, setFormData] = React.useState(
    {
      text1:
      html_defaults && html_defaults.text_1 && html_defaults.text_1.name
          ? html_defaults.text_1.name
          : "",
      text2:
      html_defaults &&  html_defaults.text_2 && html_defaults.text_2.name
          ? html_defaults.text_2.name
          : "",
      text3:
      html_defaults &&  html_defaults.text_3 && html_defaults.text_3.name
          ? html_defaults.text_3.name
          : "",
      text4:
      html_defaults &&  html_defaults.text_4 && html_defaults.text_4.name
          ? html_defaults.text_4.name
          : "",
      text5:
      html_defaults &&  html_defaults.text_5 && html_defaults.text_5.name
          ? html_defaults.text_5.name
          : "",
    },
    []
  );

  const submitChanges = () => {
    props.submitChanges({ ...formData });
  };

  const handleChange = (type, value) => {
    let newState = { ...formData };
    newState[`text${type}`] = value;
    setFormData(newState);
  };

  const renderForm = (num) => (
    <div className="form-group">
      <label>{creative_edit?.edit_popup_text_line_text || 'Text Line'} {num}</label>
      <input
        value={formData[`text${num}`]}
        type="text"
        onChange={(e) => handleChange(num, e.target.value)}
        maxLength={html_defaults[`text_${num}`].max_length}
      />
    </div>
  );
  return (
    <Modal
      visible={props.isModalVisible}
      className="bottom-modal edit-creative-text-modal"
      height={200}
      animation={"slideUp"}
      onClose={props.handleModalClose}
    >
      <span
        className="modal-close-icon"
        onClick={() => props.handleModalClose()}
      >
        <img src={closeIcon} />
      </span>
      <h3 className="heading">{creative_edit?.edit_popup_edit_text_btn_text || 'Edit Text'}</h3>
      <div className="edit-creative-text-wrap">
        { html_defaults && html_defaults.text_1  && renderForm(1)}
        { html_defaults && html_defaults.text_2  && renderForm(2)}
        { html_defaults && html_defaults.text_3  && renderForm(3)}
        { html_defaults && html_defaults.text_4  && renderForm(4)}
        { html_defaults && html_defaults.text_5 && renderForm(5)}
        <button onClick={() => submitChanges()} className="submit-btn">
          {creative_edit?.edit_popup_save_btn_text || 'Save Changes'}
        </button>
      </div>
    </Modal>
  );
};

export default CreativeEditPopUp;
