import React, { useState, useRef, useEffect } from "react";
import info from "../../Assets/info.png";
import infoBlue from "../../Assets/info_blue.png";
import "./styles.scss";

export default function Info(props) {
  const wrapperRef = useRef(null);
  const [isDropdownVisible, setisDropdownVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  const handleClickOutside = (e) => {
    if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
      return;
    }
    setisDropdownVisible(false);
  };
  return (
    <div className="info-popup-wrap" ref={wrapperRef}>
      <img
        src={props.isPrimaryDomain ? infoBlue : info}
        alt=""
        onClick={(e) => setisDropdownVisible(!isDropdownVisible)}
      />
      {isDropdownVisible ? (
        <div
          className="details-wrap"
          dangerouslySetInnerHTML={{ __html: props.details }}
        ></div>
      ) : null}
    </div>
  );
}
