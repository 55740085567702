import React from "react";
import "./style.scss";

const CardLoader = (props) => {
  const renderSocialCardLoader = () => {
    return (
      <div className="social-card-loader">
        <div className="img animate"></div>
        <div className="cta">
          <span className="animate"></span>
        </div>
      </div>
    );
  };

  const renderDomainCardLoader = () => {
    return (
      <div className="domain-card-loader">
        <div className="lazy-itemCard--name animate item1"></div>
        <div className="lazy-itemCard--name animate item2"></div>
        <div className="lazy-itemCard--name animate item3"></div>
        <div className="lazy-itemCard--name animate item4"></div>
        <div className="lazy-itemCard--name animate item5"></div>
      </div>
    );
  };

  return props.type === 2 ? (
    renderSocialCardLoader()
  ) : props.type === 3 ? (
    renderDomainCardLoader()
  ) : (
    <div className="lazy-itemCard">
      <div className="lazy-itemCard--body">
        <div className="lazy-itemCard--name animate"></div>
        <div className="lazy-itemCard--box">
          <div className="lazy-itemCard--amount animate"></div>
          <div className="lazy-itemCard--cta animate"></div>
        </div>
      </div>
    </div>
  );
};

export default CardLoader;
