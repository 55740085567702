import React from "react";
import "./style.scss";

const StickyButton = ({ children, onClick }) => {
  return (
    <div className="sticky__button">
      <div className="sticky__button--content">
        <button className="sticky__button--content-btn" onClick={onClick}>
          {children}
        </button>
      </div>
    </div>
  );
};

export default StickyButton;
