import React from "react";
import "./styles.scss";

const Input = (props) => {
  const { placeholder, value, maxLength = false, required, error = false, errorMessage = false, ...rest } = props;

  return (
    <div className="material-form-group">
      <textarea className={error ? "error" : ""} value={value} type={"text"} maxLength={maxLength} {...rest} />
      <label className={error ? "error" : ""}>
        {placeholder} {required && <small>*</small>}
      </label>
      <div className="flex items-center flex-row-reverse justify-between">
        {maxLength && (
          <span className={`lengthLabel ${error ? "error" : ""}`}>
            {value?.length}/{maxLength}
          </span>
        )}
        {errorMessage && <span className="lengthLabel error">{errorMessage}</span>}
      </div>
    </div>
  );
};

Input.defaultProps = {
  placeholder: "Add you label",
  value: "",
  maxLength: 200,
  required: false,
  resize: "none",
};

const InputMaterial = React.memo(Input);
export default InputMaterial;
