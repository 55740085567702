import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError } from "../../Components/Toast";
import config from "../../Config";
import apiCall from "../../Services/api";
import {
  saveCreativeCategoryList,
  saveCreativeBackgroundList,
  saveCreativeTemplateList,
  saveSocialTagList,
  saveSocialTemplateList,
  showLoader,
  removeLoader,
  emptySocialTemplateList,
} from "../Actions";

import * as creativeTypes from "../ActionTypes";

function* fetchCreativeCategoryList(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/catalog/getItems/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveCreativeCategoryList(response.data.data));
    }
  } catch (err) {}
}

function* fetchCreativeTemplateList(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getOfferTemplates?page=${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveCreativeTemplateList(response.data.templates));
      yield put(removeLoader());
    }
  } catch (err) {}
}

function* fetchCreativeBackgroundList(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getBackgrounds/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveCreativeBackgroundList(response.data.backgrounds));
    }
  } catch (err) {}
}

function* fetchSocialTagList(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getSocialMediaTags?page=${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveSocialTagList(response.data.tags));
    }
  } catch (err) {}
}

function* fetchSocialTemplateList(actions) {
  try {
    yield put(showLoader());

    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getSocialMediaTemplates/${actions.data.storeid}?page=${actions.data.page}&tag_id=${actions.data.tagid}`,
    });
    if (response && response.data.status) {
      if (actions.data.page === 1) {
        yield put(emptySocialTemplateList());
      }
      yield put(
        saveSocialTemplateList({
          templates: response.data.templates || [],
          isNext: response.data.is_next,
        })
      );
      yield put(removeLoader());
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("unable to fetch Social Templates!");
  }
}

function* fetchFavouriteSocialTemplateList(actions) {
  try {
    yield put(showLoader());
    if (actions.data.page === 1) {
      yield put(emptySocialTemplateList());
    }
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getFavouriteTemplates/${actions.data.storeid}?page=${actions.data.page}`,
    });
    if (response && response.data.status) {
      yield put(
        saveSocialTemplateList({
          templates: response.data.templates || [],
          isNext: response.data.is_next,
        })
      );
      yield put(removeLoader());
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("unable to fetch Social Templates!");
  }
}

function* updateFavouriteCreative(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/template/updateFavouriteFlag`,
      data: actions.data,
    });
    if (response && response.data.status) {
      if (actions.callback) {
        actions.callback(true);
      }
    } else {
      if (actions.callback) {
        actions.callback(false);
      }
    }
  } catch (err) {
    if (actions.callback) {
      actions.callback(false);
    }
  }
}

function* saveSharedTemplate(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/template/saveSharedTempaltes`,
      data: actions.data,
    });
    if (response && response.data.status) {
    }
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(creativeTypes.FETCH_CATALOG_LIST, fetchCreativeCategoryList);
  yield takeEvery(
    creativeTypes.FETCH_CREATIVE_TEMPLATE_LIST,
    fetchCreativeTemplateList
  );
  yield takeEvery(
    creativeTypes.FETCH_CREATIVE_BACKGROUNDS_LIST,
    fetchCreativeBackgroundList
  );
  yield takeEvery(creativeTypes.FETCH_SOCIAL_TAG_LIST, fetchSocialTagList);
  yield takeEvery(
    creativeTypes.FETCH_SOCIAL_TEMPLATE_LIST,
    fetchSocialTemplateList
  );
  yield takeEvery(
    creativeTypes.FETCH_FAVOURITE_SOCIAL_TEMPLATE_LIST,
    fetchFavouriteSocialTemplateList
  );
  yield takeEvery(
    creativeTypes.UPDATE_FAVOURITE_CREATIVE,
    updateFavouriteCreative
  );
  yield takeEvery(creativeTypes.SAVE_SHARED_TEMPLATE, saveSharedTemplate);
}
