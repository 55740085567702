import React, { useMemo } from "react";
import "./style.scss";
import CrossIcon from "../../../Assets/cross-black.png"

const UserCreationModule = (props) => {
  const userId = useMemo(() => (props?.customEntry ? props.userNo : props?.user?.id), [props.customEntry]);

  return (
    <section className="user-creation-module">
      <header className="flex items-center">
        <h3 className="f14px mv0 fw5">
          User Details {props?.user?.is_primary ? <span className="fw3">(Primary)</span> : null}
        </h3>
        {props?.customEntry && !!props.userNo && (
          <img width="20px" src={CrossIcon} className="pointer cross-icon" onClick={() => props.removeModule(props.userNo)} />
        )}
      </header>
      <div className="user-creation-module__body b-steel-gray-color">
        <fieldset>
          <legend className="star-dust-color">First Name</legend>
          <input
            value={props?.user?.first_name}
            name="first_name"
            onChange={(e) => props.onDataChange(e, userId)}
          />
        </fieldset>
        <fieldset>
          <legend className="star-dust-color">Last Name</legend>
          <input
            value={props?.user?.last_name}
            name="last_name"
            onChange={(e) => props.onDataChange(e, userId)}
          />
        </fieldset>
        <fieldset className="relative">
          <legend className="star-dust-color">Your Email ID</legend>
          <input
            value={props?.user?.email}
            name="email"
            onChange={(e) => props.onDataChange(e, userId)}
            className="email"
          />
          <span className="email-domain" title={`@${props?.workspaceInfo?.domain}`}>
            @{props?.workspaceInfo?.domain}
          </span>
          {props.isDuplicateEmail ? <span className="email-error">This email already exists</span> : null}
        </fieldset>
      </div>
    </section>
  );
};

export default React.memo(UserCreationModule);