/**
 * NOTICE: When using any setter to store data, then also use it's corresponding getter to
 * retreive that same data
 * @param {string} key
 * @param {any} value
 * @returns
 */
import { AUTH_TOKEN_KEY } from "./constants";
import { isJson } from "./isJson";

export function setLocalState(key, value) {
  try {
    const val = JSON.stringify(value);
    localStorage.setItem(key, val);
    return true;
  } catch (err) {
    return false; // if serialization stage throws an exception
  }
}

export function getLocalState(key) {
  try {
    const retreivedData = localStorage.getItem(key);
    if (isJson(retreivedData)) {
      const data = JSON.parse(retreivedData);
      return data;
    }
    return retreivedData; // returns either null or remaining data type
  } catch (err) {
    return undefined;
  }
}

export function removeLocalState(key) {
  try {
    localStorage.removeItem(key);
    return true; // returns either null or remaining data type
  } catch (err) {
    return false;
  }
}

export function setSessionState(key, value) {
  try {
    const val = JSON.stringify(value);
    sessionStorage.setItem(key, val);
    return true;
  } catch (err) {
    return false; // if serialization stage throws an exception
  }
}

export function getSessionState(key) {
  try {
    const retreivedData = sessionStorage.getItem(key);
    if (isJson(retreivedData)) {
      const data = JSON.parse(retreivedData);
      return data;
    }
    return retreivedData; // returns either null or remaining data type
  } catch (err) {
    return undefined;
  }
}

export function removeSessionState(key) {
  try {
    sessionStorage.removeItem(key);
    return true; // returns either null or remaining data type
  } catch (err) {
    return false;
  }
}

export const CONSTANTS = {
  SESSION: {
    PREMIUM_PURCHASE_TYPE: "premiumPurchaseType",
    PRE_PAYMENT_DATA: "prePaymentData",
    IS_DOMAIN_RENEWAL: "isDomainRenewal",
    IS_THEME_UPSELL: "isThemeUpsell",
    SOCIAL_PURCHASE_IMG_OBJ: "socialPurchaseImgObj",
  },

  LOCAL: {
    SUBSCRIPTION_CART_ID_KEY: "subscriptionCartId",
    STORE_ID: "storeid",
    TOKEN: "token",
    ORDER_ID: "orderID",
    TRANSACTION_ID: "txnID",
    TEMP_TOKEN: "temporary-token"
  },
};

export const getAuthToken = () => getLocalState(AUTH_TOKEN_KEY);