import React from "react";
import { ROUTES } from "../../Utils/constants";
import history from "../../Utils/history";
import cartIcon from "../../Assets/cart.png";
import "./styles.scss";

function BuyDomainButton(props) {
  const { additionalParameters, className = "", style } = props;

  const buyANewDomain = () =>
    history.push(`${ROUTES.DOMAIN_SEARCH}${additionalParameters}`);

  return (
    <button
      className={`custom-buy-domain-btn btn-reset mt3 flexRow ${className}`}
      style={style}
      onClick={buyANewDomain}
    >
      <img src={cartIcon} />
      <p className="ma0">Buy a new domain</p>
    </button>
  );
}

export default BuyDomainButton;
