import React from "react";
import { LockIcon, SecureIcon } from "../svgIcons";

const StickySecurePaymentText = () => {
  return (
    <div className="flex items-center justify-center fixed bottom-0 pv3 bg-white w-100 ba b--black-10">
      <div className="flex items-center gap2">
        <div className="flex items-center justify-between">
          <LockIcon />
          <span className="jakarta fw5 f10rem black-60">Secure Payment</span>
        </div>
        <div
          style={{
            height: "2px",
            width: "2px",
            borderRadius: "50%",
            backgroundColor: "#666666",
          }}
        ></div>
        <div className="flex items-center justify-between gap1">
          <SecureIcon />
          <span className="jakarta fw5 f10rem black-60">
            256-bit SSL encryption
          </span>
        </div>
      </div>
    </div>
  );
};

export default StickySecurePaymentText;
