import React from "react";
import { doesStoreAddressExist } from "../../../Utils/common";
import history from "../../../Utils/history";
import { ROUTES } from "../../../Utils/constants";
import { useContext } from "react";
import { ParentContext } from "../../../parentProvider";
import LineArrow from "../../../Assets/line-arrow-white.svg";
import { useSelector } from "react-redux";
import GmailIcon from "../../../Assets/google_mail.png";
import "./style.scss";
import { getUrlParams } from "../../../Utils/getUrlParams";

const AccountSetupCard = (props) => {
  const [domain] = getUrlParams(["domain"]);
  const contextData = useContext(ParentContext);
  const { additionalParameters } = contextData;
  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.data.store,
  }));

  const onSetupAccCardClick = () => {
    if (!doesStoreAddressExist(storeData)) {
      props.toggleModal();
    } else {
      history.push(
        `${ROUTES.WORKSPACE_CREATE_ACCOUNT}${additionalParameters}&domain=${domain}`
      );
    }
  };

  return (
    <section className="account-setup-section">
      <div className="setupAccCard" onClick={onSetupAccCardClick}>
        <div className="mb12px">
          <span className="gmail-icon">
            <img src={GmailIcon} alt="gmail icon" />
          </span>
        </div>
        <div>
          <h3 className="f14px fw5 mt0 mb1">Setup your Email Account(s)</h3>
          {props.unAssignedUsersCount > 0 ? (
            <p className="f12px fw3 mv0">
              {props.unAssignedUsersCount} Unassigned Email Account(s) available
            </p>
          ) : null}
        </div>
      </div>
      <div
        className="setupNowButton flex justify-center items-center w-100"
        onClick={onSetupAccCardClick}
      >
        <p className="white f14px mv0 flex items-center justify-center">
          Setup now
          <img src={LineArrow} width="16px" className="ml1" />
        </p>
      </div>
    </section>
  );
};

export default AccountSetupCard;
