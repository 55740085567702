/**
 * Payment Modal Component
 */
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";

import Modal from "./../Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentMethods,
  initLazyPayPayment,
  initPayment,
  savePaymentInfo,
  toggleLazyPayAuthModal,
} from "../../Redux/Actions";
import { getPackageName, getPaymenthodName } from "../../Utils/common";
import history from "../../Utils/history";
import { getDeviceInfo } from "../../Utils/deviceInfo";
import shakeHandIcon from "./../../Assets/ic_onetime@2x.png";
import exchangeicon from "./../../Assets/ic_exchange@2x.png";
import blackExchangeIcon from "./../../Assets/blackExchangeIcon.png";
import blackShakeHandIcon from "./../../Assets/blackShakeHandIcon.png";

import { trackAppEvents } from "../../Utils/androidEvents";
import { ParentContext } from "../../parentProvider";
import LazyPayAuthModal from "../LazyPayAuth";
import { isAndroid } from "../../Utils/isAndroid";

import {
  CONSTANTS,
  removeSessionState,
  setLocalState,
  setSessionState,
} from "../../Utils/storage";
import {
  LAZYPAY_AUTH_MODAL_VIEWS,
  SUBSCRIPTION_TYPE,
} from "../../Utils/constants";
import StickySecurePaymentText from "./components/StickySecurePaymentText";
import {
  CardsIcon,
  CrossIcon,
  GooglePayIcon,
  PaytmIcon,
  PhonepeIcon,
} from "./svgIcons";
import DiscountOfferStrip from "./components/DiscountOfferStrip";
import StickyButton from "../StickyButton";
import GSTInput from "./components/GSTInput";
import roundUptoTwoDecimal from "../../Utils/roundUptoTwoDecimal";
import { ToastNotifyWarn } from "../Toast";

const PaymentModal = (props) => {
  const contextData = useContext(ParentContext);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  const { storeid, token, additionalParameters } = contextData;
  const dispatch = useDispatch();
  const {
    methods,
    prePaymentData,
    downPaymentMethods,
    availableUpiPaymentOptions,
  } = useSelector((state) => state.paymentReducer);
  const storeData = useSelector((state) => state.storeReducer.data.store);
  const { executeBeforePaymentInitiate = () => {} } = props;

  const hasAvailableUpiOptions =
    availableUpiPaymentOptions && Array.isArray(availableUpiPaymentOptions);
  const isAndroidDevice = isAndroid();

  useEffect(() => {
    if (props.show === true && prePaymentData.amount) {
      dispatch(
        fetchPaymentMethods({
          id: 2,
          amount: prePaymentData?.amount,
        })
      );
      setActivePaymentMethod(null);
    }
  }, [props.show, prePaymentData]);

  useEffect(() => {
    if (
      hasAvailableUpiOptions &&
      !availableUpiPaymentOptions.length &&
      props.show
    ) {
      ToastNotifyWarn("No UPI option available on device!");
    }
  }, [props.show]);

  const submitPayment = (method, id) => {
    setSessionState(CONSTANTS.LOCAL.STORE_ID, storeid);
    setSessionState(CONSTANTS.LOCAL.TOKEN, token);
    if (props.fromWorkSpace) {
      trackAppEvents("GW_Buy", {
        storeid: storeid,
        PaymentMode: method,
      });
    } else if (props.fromAds) {
      trackAppEvents("GA_Payment ", {
        storeid: storeid,
        Ad_Budget: prePaymentData?.amount,
        PaymentMode: method,
      });
    } else {
      if (!props.fromDomain) {
        trackAppEvents("Buy_Theme", {
          storeid: storeid,
          themeid: props?.themeId,
          paymentMode: method,
        });
      } else {
        trackAppEvents(
          Number(sessionStorage.getItem("prePaymentData")) >= 400
            ? "Domain_Buy_Plus"
            : "Domain_Buy",
          {
            storeid: storeid,
            themeid: props?.domainName || "",
            paymentMode: method,
            channel: localStorage.getItem("channel"),
          }
        );
      }
    }
    /** for domain renewal the purchase type is same as that of initial domain purchase i.e. 2
     * therefore to differentiate after purchase flow
     *  */
    if (props.fromDomainRenewal) {
      setSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL, true);
    } else removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);

    if (props.fromThemeUpsell) {
      setSessionState(
        CONSTANTS.SESSION.IS_THEME_UPSELL,
        JSON.stringify({
          isThemeUpsell: true,
          purchaseType: props.themeTypeToBePurchased,
        })
      );
    }

    const deviceInfo = getDeviceInfo();
    const payload = {
      payment_option_id: id,
      phone: storeData?.owner?.phone,
      orderID: prePaymentData?.orderID,
      paymentMethod: method,
      merchantID: prePaymentData?.merchantID,
      deviceType: deviceInfo.OSName,
      browserType: deviceInfo.BrowserName,
      userAgent: deviceInfo.userAgent,
      source: "premium",
      merchantName: `Digital Showroom - ${storeData?.store_info?.name}`,
    };
    dispatch(savePaymentInfo(payload));

    executeBeforePaymentInitiate();

    if (method === "card") {
      history.push(
        `/payment/card/${prePaymentData?.orderID}${additionalParameters}`,
        {
          fromAdManger: props.fromAds,
          fromDomainLandingPage: props?.isDomainSearchPage || false,
        }
      );
      return;
    }

    if (method === "cemi") {
      history.push(
        `/payment/cemi/${prePaymentData?.orderID}${additionalParameters}`,
        { fromAdManger: props.fromAds }
      );
      return;
    }

    if (method === "lazyPay") {
      payload.phone = storeData?.owner?.phone;
      payload.amount = prePaymentData?.amount;
      payload.store_id = storeData?.store_id;
      dispatch(initLazyPayPayment(payload));
    } else {
      dispatch(initPayment(payload, history));
    }
    setLocalState(CONSTANTS.LOCAL.ORDER_ID, prePaymentData?.orderID);
  };

  const onCloseLazyPayAuthModal = () => {
    dispatch(
      toggleLazyPayAuthModal({
        show: false,
        view: LAZYPAY_AUTH_MODAL_VIEWS.NONELIGIBLE,
      })
    );
  };

  const checkIfShouldShowMethod = (method) => {
    const packageName = getPackageName(method);
    return isAndroidDevice && hasAvailableUpiOptions && packageName
      ? availableUpiPaymentOptions.includes(packageName)
      : true;
  };

  const selectPayment = (item) => {
    setActivePaymentMethod(item);
  };

  const onProceedToPayment = () => {
    props.toggleModal(true);
    activePaymentMethod === "paytm"
      ? submitPayment(activePaymentMethod, 2)
      : submitPayment(activePaymentMethod, 1);
  };

  const closeModal = () => props.toggleModal();

  const renderPaymentIcon = (method) => {
    switch (method) {
      case "googlepay":
        return <GooglePayIcon />;
      case "card":
        return <CardsIcon />;
      case "phonepe":
      case "pswitch":
        return <PhonepeIcon />;
      case "paytm":
      case "paytmupi":
        return <PaytmIcon />;
      default:
        return <img src={`//cdn.dotpe.in/cfe/image/${method}.png`} />;
    }
  };

  const totalPaymentMethods = [...methods, ...downPaymentMethods];
  const finalMethods = totalPaymentMethods.filter((method) =>
    checkIfShouldShowMethod(method)
  );

  return (
    <>
      <Modal
        visible={props.show}
        className={`bottom-modal pre-payment-modal ${
          props.fromDomain && "bg-white"
        }`}
        // height={650}
        animation={"slideUp"}
        onClose={closeModal}
        style={{
          backgroundColor: "#fff",
          zIndex: 9999999,
        }}
      >
        <div className="pre-payment-modal__wrapper">
          <div className="flex justify-end items-center w-100 pr18px pt20px">
            <span onClick={closeModal}>
              <CrossIcon />
            </span>
          </div>
          {(props?.fromTheme || props?.fromThemeUpsell) && (
            <div className="ph16px">
              {props.fromThemeUpsell ? (
                <section className="payment-modal_title mb-5 mt-20">
                  {props.themeTypeToBePurchased === SUBSCRIPTION_TYPE.THEME_ONLY
                    ? "Get Only Theme pack"
                    : "Get Theme Plus pack"}
                </section>
              ) : (
                <section className="payment-modal_title">
                  {prePaymentData?.upgrade === 1
                    ? "Get this theme"
                    : "Get Premium pack"}
                </section>
              )}
              <section className="payment-modal__information">
                <div>
                  <img
                    src={
                      props.fromThemeUpsell ? blackExchangeIcon : exchangeicon
                    }
                    alt="Exchange icon"
                  ></img>
                  Exchange <br></br>Theme Anytime
                </div>
                <div>
                  <img
                    src={
                      props.fromThemeUpsell ? blackShakeHandIcon : shakeHandIcon
                    }
                    alt="shake hand icon"
                  ></img>
                  One Time Fee<br></br> Lifetime Validity
                </div>
              </section>
            </div>
          )}

          <section className="payment-modal_payAmount">
            Pay ₹{roundUptoTwoDecimal(prePaymentData?.amount)}
          </section>
          {props?.isDomainSearchPage ? (
            <section className="ph16px">
              <DiscountOfferStrip />
            </section>
          ) : null}
          <GSTInput show={props.show} prePaymentData={prePaymentData} />
          <section className="payment-modal_container">
            <div className="payment-modal_container-text">
              Select one payment method to pay
            </div>
            <ul>
              {finalMethods?.map((item) => {
                return (
                  <li
                    className={`payment-method--type flex flex-column items-center`}
                    key={item}
                    onClick={() => selectPayment(item)}
                  >
                    <div
                      className={`payment-method--type-card ${
                        item === activePaymentMethod
                          ? "payment-method--type-card-active"
                          : ""
                      }`}
                    >
                      {renderPaymentIcon(item)}
                    </div>
                    <span
                      className={`payment-method--type-name ${
                        item === activePaymentMethod
                          ? "payment-method--type-name-active"
                          : ""
                      } `}
                    >
                      {getPaymenthodName(item)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </section>
          <section className="payment-modal_cuishione"></section>
        </div>
        {activePaymentMethod ? (
          <StickyButton onClick={onProceedToPayment}>
            Pay using {getPaymenthodName(activePaymentMethod)}
          </StickyButton>
        ) : (
          <StickySecurePaymentText />
        )}
      </Modal>
      <LazyPayAuthModal onClose={onCloseLazyPayAuthModal} />
    </>
  );
};

export default PaymentModal;
