import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDomainRenewedModal } from "../../Redux/Actions";
import successIcon from "../../Assets/success.png";
import "./styles.scss";
import { Modal } from "..";
import { CONSTANTS, removeSessionState } from "../../Utils/storage";

export default () => {
  const dispatch = useDispatch();
  const domainRenewedModal = useSelector(
    (store) => store.domainReducer.domainRenewedModal
  );
  const closeModal = () => dispatch(toggleDomainRenewedModal(false));

  useEffect(() => {
    if (domainRenewedModal)
      removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
  }, [domainRenewedModal]);

  return (
    <Modal
      visible={domainRenewedModal}
      className="bottom-modal domain-renewal-success-modal"
      height={50}
      animation={"slideUp"}
      onClose={closeModal}
    >
      <div className="body-wrapper flexColumn items-center">
        <img src={successIcon} className="success-icon mt3 obFit" />
        <p className="fw6 f20px mb0 mt4 tc">Congratulations</p>
        <p className="fw3 f14px mb3 mt2 tc mt1">Your Domain is renewed</p>
        <button
          className="ma0 btn-reset ma0 mt4 flexRow w-50 white bg-black br5 pa8 justify-center mb0"
          onClick={closeModal}
        >
          <p className="ma0">Okay</p>
        </button>
      </div>
    </Modal>
  );
};
