// delete component

import React, { useContext, useMemo } from "react";
import { Modal } from "../..";
import { ParentContext } from "../../../parentProvider";
import history from "../../../Utils/history";
import alertIcon from "../../../Assets/alert.png";
import "./styles.scss";
import { ROUTES } from "../../../Utils/constants";
import { toggleWorkspaceExpiryWarningModal } from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { domainExpiryCalculator } from "../../../Utils/domainHelper";

const WorkspaceExpiringWarningPopup = (props) => {
  const { domainDetails } = props;
  const contextData = useContext(ParentContext);
  const dispatch = useDispatch();
  const { additionalParameters, token, storeid } = contextData;
  const showWorkspaceExpiryWarningModal = useSelector(
    (state) => state.workspaceReducer.showWorkspaceExpiryWarningModal
  );

  const onClose = () => dispatch(toggleWorkspaceExpiryWarningModal(false));

  const goToDomainRenewalDetails = () => {
    onClose();
    history.push(
      `${ROUTES.DOMAIN_RENEWAL_DETAILS}${additionalParameters}&domain-name=${props.domainName}`
    );
  };

  const hasDomainExpired = useMemo(
    () =>
      domainExpiryCalculator(
        domainDetails?.expiry_date ||
          domainDetails?.expire_date ||
          domainDetails?.expiry
      ).hasDomainExpired,
    [props]
  );

  return (
    <Modal
      visible={showWorkspaceExpiryWarningModal}
      className="bottom-modal domain-info-modal"
      showCloseButton={true}
      height={100}
      animation={"slideUp"}
      onClose={onClose}
    >
      <div className="details-row">
        <img src={alertIcon} className="alert-icon" />
        <p className="fw6 f22px mb3 mt2">{props.domainName}</p>
        <p
          className="f14px ma0 lh-copy"
          style={{
            color: "#535353",
          }}
        >
          Dear User, your workspace account may stop working because the domain
          associated with it{" "}
          {hasDomainExpired ? `has expired` : `is about to expire`}.
          <br />
          <span
            style={{
              color: "#C73737",
            }}
          >
            Please renew your domain to prevent workspace from expiring
          </span>
        </p>
        <button
          className="ma0 btn-reset ma0 mt4 flexRow w-100 white bg-black br5 pa8 justify-center mb0"
          onClick={goToDomainRenewalDetails}
        >
          <p className="ma0">Renew Now</p>
        </button>
      </div>
    </Modal>
  );
};

export default WorkspaceExpiringWarningPopup;
