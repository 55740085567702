import React from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";

const AdPreview = ({
  heading1,
  heading2,
  heading3,
  description1,
  description2,
  border
}) => {
  const { storeData, isLoaderVisible } = useSelector((state) => ({
    storeData: state.storeReducer.data.store,
    isLoaderVisible: state.loaderReducer.isLoaderVisible,
  }));

  return (
    <div className={`adpreview-block ${border && "border"}`}>
      <article className="href-url">
        <strong>Ad. </strong>
        {storeData?.store_info?.store_url}
      </article>
      <article className="headlines">
        {heading1 || heading2 || heading3
          ? `${heading1} ${heading2 ? `| ${heading2}` : ''} ${heading3 ? `| ${heading3}` : ''}`
          : "Part 1 | Part 2 | Part 3"}
        {/* {`${heading1 || "Part 1"}
        ${heading2 ? ` | ${heading2}` : " | Part 2"}
        ${heading3 ? ` | ${heading3}` : " | Part 3"}`.substr(0, 100)} */}
      </article>
      <article className="description">
        {description1
          ? ` ${description1} ${description2}`
          : " Ex. A Google Ads description is usually 90 characters and describes your business in general."}
      </article>
    </div>
  );
};

export default AdPreview;
