import React, { useContext } from "react";
import moment from "moment";
import AdPreview from "./../AdPreview";
import arrowFor from "./../../Assets/Arrow-Dark.png";
import alarmIcon from "./../../Assets/alarm@2x.png";
import "./styles.scss";
import history from "../../Utils/history";
import { ParentContext } from "../../parentProvider";
import AdStatusIcon from "./../AdStatusIcon";
import { getCompletedStepsListforAd } from "./../../Utils/common";
import { trackAppEvents } from "../../Utils/androidEvents";

const AdStatusCard = ({ adData }) => {
  const contextData = useContext(ParentContext);
  const { storeid, additionalParameters } = contextData;
  const isDraftedAd = [1, 2].includes(adData?.status);

  const onClicHandle = () => {
    if (isDraftedAd) {
      trackAppEvents("GA_DraftEdit ", {
        storeid,
        id: adData.id,
      });
      history.push(`/ad/step/1${additionalParameters}`, { adId: adData.id });
    } else {
      trackAppEvents("GA_Details ", {
        storeid,
        id: adData.id,
      });
      history.push(`/ad-details/${adData?.id}${additionalParameters}`);
    }
  };

  const _renderForDraft = () => {
    const stepsRemain = 4 - getCompletedStepsListforAd(adData).length;
    return (
      <div className="draft-view">
        {stepsRemain > 0 && (
          <p className="step-counter">
            {stepsRemain} Step{stepsRemain > 1 ? "s" : ""} Remaining
          </p>
        )}
        <p className="headline">{`${adData?.ad_info?.heading1} ${
          adData?.ad_info?.heading2 ? `| ${adData?.ad_info?.heading2}` : ""
        } ${
          adData?.ad_info?.heading3 ? `| ${adData?.ad_info?.heading3}` : ""
        }`}</p>
        <p className="timing">
          <span>{moment(adData.updated_at).format("hh:mm a")}</span>
          <span>|</span>
          <span>{moment(adData.updated_at).format("DD/MM/YYYY")}</span>
        </p>
      </div>
    );
  };

  return (
    <div className="adstatus-card">
      {/* <article className="end-note">
        <img src={alarmIcon} alt="alert icon" />
        This ad is about to end
      </article> */}
      <article className="adstatus-card__preview">
        {isDraftedAd ? (
          _renderForDraft()
        ) : (
          <AdPreview
            heading1={adData?.ad_info?.heading1}
            heading2={adData?.ad_info?.heading2}
            heading3={adData?.ad_info?.heading3}
            description1={adData?.ad_info?.description1}
            description2={adData?.ad_info?.description2}
          />
        )}
      </article>
      <article className="adstatus-card__status">
        <AdStatusIcon adData={adData} />
        <div className="details" onClick={onClicHandle}>
          {isDraftedAd ? "Edit" : "Details"}{" "}
          <img src={arrowFor} alt="details action" />
        </div>
      </article>
    </div>
  );
};

export default AdStatusCard;
