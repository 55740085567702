import React from "react";
import history from "../../../Utils/history";
import "./style.scss";
import ArrowBack from "../../../Assets/arrowb.png";

const WorkspacePageHeader = () => {
  return (
    <header className="workspace-header-wrapper">
      <img src={ArrowBack} alt="arrow back" className="h2" onClick={history.goBack} />
      <span className="page-title">Google Workspace</span>
    </header>
  )
}

export default React.memo(WorkspacePageHeader);