import React, { useEffect, useState } from "react";
import moment from "moment";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchStoreInfo } from "../../Redux/Actions";

const ThemeTrailTimer = (props) => {
  var x = null;
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");
  const token = urlParams.get("token");
  const storeData = useSelector((state) => state.storeReducer.data);
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState({
    hour: "00",
    minute: "00",
    second: "00",
  });

  const isLoaderVisible = useSelector(
    (state) => state.loaderReducer.isLoaderVisible
  );

  useEffect(() => {
    _timer();
    return () => {
      clearInterval(x);
      x = null;
      while (x !== null) {
        x = null;
      }
    };
  }, [storeData]);

  const _timer = () => {
    clearInterval(x);
    let countDownDate = "";
    if (window.Android) {
      countDownDate = new Date(storeData?.store?.theme?.expiry).getTime(); // The deadline
    } else {
      countDownDate = new Date(
        storeData?.store?.theme?.expiry.replace(" ", "T")
      ).getTime(); // The deadline
    }

    x = setInterval(() => {
      let now = new Date().getTime();

      let distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({
        hour: hours > 0 ? hours : "00",
        minute: minutes < 10 ? "0" + minutes : minutes,
        second: seconds < 10 ? "0" + seconds : seconds,
      });

      if (distance < 0) {
        clearInterval(x);
        dispatch(fetchStoreInfo(token));
      }
    }, 1000);
  };

  return (
    !isLoaderVisible && (
      <div className="themeTrail--timer">
        <span>
          <label className="number">{timeLeft.hour}</label>
          <label className="text">H </label>
        </span>
        <span className="dot">:</span>
        <span>
          <label className="number">{timeLeft.minute}</label>
          <label className="text">M </label>
        </span>
        <span className="dot">:</span>
        <span>
          <label className="number">{timeLeft.second}</label>
          <label className="text">S</label>
        </span>
      </div>
    )
  );
};

export default ThemeTrailTimer;
