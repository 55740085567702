import React from "react";
import "./style.scss";
import WhiteCheck from "../../Assets/tick-white.png"

const Checkbox = (props) => {
  return (
    <div className="checkbox-container relative">
      <input
        type="checkbox"
        checked={props.isPrimary ? true : props.checked}
        {...props}
        className={props.checked ? "checked" : ""}
      />
      {props.checked ? <img src={WhiteCheck} onClick={!props.isPrimary && props.onChange} /> : null}
    </div>
  );
};

export default Checkbox;