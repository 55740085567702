import React from "react";
import "./style.scss";

const LinearProgress = (props) => {
  return (
    <div className="linear-progress">
      <div className="progress-background"></div>
      <div
        className="progress-bar bg-napoli-blue-color"
        style={{
          left: `${props.value - 100}%`
        }}
      ></div>
    </div>
  );
};

export default LinearProgress;