import moment from "moment";
import store from "../Redux/Store";
import { getTimeDateDiff } from "./getTimeDateDiff";
import { DOMAIN_PURCHASE_EXTERNAL_SOURCE } from "./constants";

export function domainExpiryCalculator(expirytime) {
  const storeData = store.getState().storeReducer.data.store;
  const gracePeriodDays = storeData?.constants?.domain_renewal_grace_period_in_days
  // required as we are not taking the time into consideration but only days, so diffs will be calculated between days
  const expiryTimeInDays = moment(expirytime).format("YYYY-MM-DD");
  // extracted to show days remaining in UI
  const domainExpiryDaysDiff = getTimeDateDiff(
    moment().format("YYYY-MM-DD"),
    expiryTimeInDays,
    "days"
  );
  const domainExpirySecondsDiff = getTimeDateDiff(null, expirytime, "seconds");
  // not yet expired, but in the final 30 days of validity
  const isDomainExpiring =
    domainExpiryDaysDiff <= 30 && domainExpiryDaysDiff >= 0;
  const hasDomainExpired = domainExpiryDaysDiff < 0;
  /** Offer Period is provided in between 10 & 30 days before expiring after which the merchant gets no offer on renewing */
  const isOfferPeriodAvailable =
    domainExpiryDaysDiff <= 30 && domainExpiryDaysDiff >= 11;
  // variable grace period where the merchant would be able to renew the same domain even after it has expired
  const gracePeriod = moment(expiryTimeInDays).add(gracePeriodDays, "days");
  const isGracePeriodAvailable = domainExpiryDaysDiff >= (gracePeriodDays * -1);

  return {
    isDomainExpiring,
    hasDomainExpired,
    isOfferPeriodAvailable,
    isGracePeriodAvailable,
    daysDiff: domainExpiryDaysDiff,
    isToday: domainExpiryDaysDiff === 0,
    gracePeriod
  };
}


