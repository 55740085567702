import React from "react";
import Modal from "../Modal";
import closeIcon from "./../../Assets/close.png";
import "./style.scss";
import { useSelector } from "react-redux";
import CameraIcon from "./.../../../../Assets/camera-iamge.jpeg";
import { trackAppEvents, sendDataToApp } from "../../Utils/androidEvents";

const CreativeBackgroundPopUp = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");
  const token = urlParams.get("token");
  const backgroundData = useSelector(
    (state) => state.creativesReducer.businessCreatives.backgrounds
  );
  const { creative_edit } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );

  const submitChanges = (value, type, textColor, index) => {
    if (document.getElementById("temp_bg")) {
      if (type === "solid") {
        document.getElementById("temp_bg").style.background = value;
        if (document.getElementById("addImage")) {
          window.document.getElementById("addImage").style.display = "none";
        }
      } else {
        sendDataToApp({
          convertImage: true,
          imageArray: value,
          imageContent: value,
          data: value,
        });
      }
      // document.getElementById("temp_bg").style.color = textColor;
      props.handleModalClose(true);
    } else {
      alert("HTML ID mismatch");
    }

    trackAppEvents("Mkt_Offers_Edit_Background", {
      storeid: store_id,
      template_id: props.templateId,
      background_id: index,
      from_gallery: false,
    });
  };

  const onChosseGalleryBtnClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ openGallery: true })
      );
    }
    trackAppEvents("Mkt_Offers_Edit_Background", {
      storeid: store_id,
      template_id: props.templateId,
      from_gallery: true,
    });
    props.handleModalClose(true);
  };

  return (
    <Modal
      visible={props.isModalVisible}
      className="bottom-modal edit-creative-background-modal"
      height={200}
      animation={"slideUp"}
      onClose={props.handleModalClose}
    >
      <span
        className="modal-close-icon"
        onClick={() => props.handleModalClose()}
      >
        <img src={closeIcon} />
      </span>
      <h3 className="heading">
        {creative_edit?.background_popup_title || "Edit Background"}
      </h3>
      <div className="edit-creative-bg-wrap">
        {window.Android ? null : (
          <article className="gallery-wrap" onClick={onChosseGalleryBtnClick}>
            <img src={CameraIcon}></img>
            <span>
              {creative_edit?.background_popup_choose_image_text ||
                "Choose image"}{" "}
              <br></br>
              {creative_edit?.background_popup_from_gallery_text ||
                "from gallery"}
            </span>
          </article>
        )}
        <article className="background-box">
          {backgroundData &&
            backgroundData.length > 0 &&
            backgroundData.map((item, index) => (
              <div
                className="item"
                key={`${index}-background-colors`}
                onClick={() =>
                  submitChanges(item.name, item.type, item.text_color, index)
                }
                style={{
                  background:
                    item.type === "image" ? `url(${item.name})` : item.name,
                  backgroundSize: "cover",
                }}
              ></div>
            ))}
        </article>
      </div>
    </Modal>
  );
};

export default CreativeBackgroundPopUp;
