import config from ".";

export const ENDPOINTS = {
  LOGIN: {
    generateOTP: (number) => "/dotk/vo1/user/generateOtp/" + number,
    verifyOpenOTP: "/dotk/vo1/user/loginAndCreateStore",
  },
  DOMAIN: {
    SEARCH_DOMAIN: "/dotk/domain/searchDomain",
    SEARCH_OPEN_DOMAIN: (domain) => `/dotk/vo1/domain/search/${domain}`,
    initiateDomainSetupPurchase: () => "/dotk/domain/initiateSetupPurchase",
    GET_DOMAIN_ANALYTICS: "/dotk/vo1/domain/getDomainAnalytics",
    // Domain renewal
    INITIATE_DOMAIN_PURCHASE: "/dotk/domain/initiateDomainPurchase",
    INITIATE_DOMAIN_RENEWAL: "/dotk/vm1/premium/initiateDomainRenewal",
    GET_ALL_DOMAIN_LIST: "/dotk/vm1/premium/storeDomains",
    DOMAIN_CART: `/dotk/vm1/premium/subscriptionCart`,
    INITIATE_DOMAIN_CART_PURCHASE: `/dotk/vm1/premium/initiateCartPurchase`,
    LATEST_DOMAIN_DETAILS: `/dotk/vm1/premium/latestDomain`,
    getDomainRenewalDetails: ({ domainName, year }) =>
      `/dotk/vm1/premium/domainRenewalDetails?domain=${domainName}&year=${year}`,
    checkDomainDetails: (domainData) =>
      `/dotk/vm1/premium/checkDomainAvailability/${domainData}`,
    REQUEST_TO_CALLBACK: `/dotk/vm1/premium/addRequestToCallback/v2`,
    getDomainBannerInfo: "/dotk/vm1/user/staffMember",
  },
  PAYMENT: {
    initiatePayment: `${config.old_base_url}/payment/v2/transaction/initiate`,
    verifyPayment: (txnID) => `/payment/v2/transaction/verify/${txnID}`,
    verifyPaymentForAirtel: (txnID) =>
      `/payment/v2/transaction/verify/${txnID}`,
    pullPaymentStatus: (txnID) => `/payment/v2/transaction/status/${txnID}`,
    // Lazypay
    initiateLazypayPayment: "/dotk/vc1/settings/initiateLazyPayPayment",
    verifyLazypayOtp: `${config.old_base_url}/payment/v2/lazyPay/otp`,
    verifyGSTForPurchase: "/dotk/vm1/premium/verifyGstForPurchase",
  },
  getSubscriptionDetails: `/dotk/vm1/premium/subscriptions`,
  getShoppingAccountDetailsApi: (storeId) =>
    `/dotk/vm1/marketing/shopping/accountDetails/${storeId}`,
  WORKSPACE: {
    initiateWorkspacePurchase: (isUpsell) =>
      `/dotk/vm1/premium/workspace/initiatePurchase${
        isUpsell ? "?type=5" : ""
      }`,
    getWorkspaceDetails: (domainName) =>
      `/dotk/vm1/premium/workspace/account?domain=${domainName}`,
    provisionAndAddUserMail: "/dotk/vm1/premium/workspace/licenses",
    getWorkspaceLicense: (data) =>
      `/dotk/vm1/premium/workspace/account/${data.account_id}/license/${data.licence_id}`,
    workspacePriceDetails: (actions) =>
      `/dotk/vm1/premium/workspace/plans?count=${actions.data.count}&domain=${
        actions.data.domain
      }${actions.isUpsell ? "&type=5" : ""}`,
    workspaceRenewalDetails: (data) =>
      `/dotk/vm1/premium/workspace/renewalDetails?domain=${data}`,
    initiateWorkspaceRenewal: `/dotk/vm1/premium/workspace/initiateRenewal`,
  },
  CATALOG: {
    getItemsBasicDetailsByStoreId: (storeId) =>
      `/dotk/catalog/getItemsBasicDetailsByStoreId/${storeId}`,
      getCollectionCategory: (storeId) =>
      `/dotk/vm1/products/collectionCategoryData/${storeId}`,
    shareCatalog: `/dotk/vm1/marketing/generateCustomCatalogPdf`,
    getItemsDetailsByItemIds: "/dotk/vm1/products/getItemsDetailsByItemIds",
    getGSTBillingDetails: "/dotk/vm1/settings/gstBillingInfo",
    getCollectionCategoryItemData: (storeId, collectionId, categoryId) =>
    `/dotk/vm1/products/collectionCategoryItemsData?store_id=${storeId}&collection_id=${collectionId}&category_id=${categoryId}`,
    seoBasicRecommendations: (storeId, type) =>
    `/dotk/vm1/marketing/seoBasicRecommendations/${storeId}?type=${type}`,
  },
  MARKETING: {
    initiateWABAPurchase: () => "/dotk/vm1/premium/initiateWabaPurchase",
    initiateSocialSharePurchase: () =>
      "/dotk/vm1/marketing/initiateSocialMediaTemplatePurchase",
  },
  CART: {
    getOrderCartByIdApi: "/dotk/vm1/orders/getOrderCartById",
  },
  COMMONS: {
    fetchFeatureLocks: () => "/dotk/vm1/premium/featureLocks",
    helpSectionRoute: "/dotk/vo1/marketing/helpArticleRoutes",
    GET_APP_CONSTANTS: "/dotk/vm1/premium/storeConstants",
    GET_DLP_APP_CONSTANTS: "/dotk/vo1/domain/landingPageConstants",
    UPDATE_STORE_SERVICES_FLAG: "/dotk/vm1/settings/storeServicesFlag",
  },
  EMI_API: {
    GET_EMI_OPTIONS_FOR_CARD: `/dotk/vm1/settings/getEmiOptionsSpecificToCard`,
  },
  getSubscriptionPaymentDetailWithCoupon: "/dotk/vm1/premium/coupon/details/",
  initiateSubscriptionsPurchaseUsingCoupon:
    "/dotk/vm1/premium/initiateSubscriptionsPurchase",
};
