import "./style.scss";
import { Modal } from "../..";
import MailIcon from "../../../Assets/material-mail-outline.svg";
import { useSelector } from "react-redux";
import React from "react";

const GenericWorkspaceModal = (props) => {
  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.data.store,
  }));

  const handleContactNow = () => {
    const mailString = `mailto:expert@dotpe.in?subject=Support request for Digital Showroom <Store ID -(${
      storeData?.store_id ? storeData?.store_id : ""
    })>&body=%28Please do not edit subject line - Digital Showroom %7CStore ID - %3C${
      storeData?.store_id
    }%3E%29%0D%0A%0D%0ALet our experts know what issue you are facing%0D%0AStart writing below %F0%9F%91%87`;
    window.open(mailString, "_blank");
  };

  return (
    <Modal
      visible={props.open}
      customStyles={{
        maxHeight: 125,
        minHeight: 125
      }}
      className="centered-modal generic-workspace-modal"
      animation={"slideUp"}
      onClose={props.close}
    >
      <p className="mt0 fw5">{props.modalBody}</p>
      <div className="flex justify-end">
        <button className="btn-primary br-pill" onClick={handleContactNow}>
          <img src={MailIcon} className="btn-img mr2" />
          Contact Now
        </button>
      </div>
    </Modal>
  );
};

export default GenericWorkspaceModal;