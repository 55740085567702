import React from "react";
import { GreatIcon } from "../../../Assets/svg";
import { useAppConstants } from "../../../Hooks/useAppConstants";
import { DOMAIN_CART_PRICES_CONSTANTS } from "../../../constants";

const DiscountOfferStrip = ({ className, domainData }) => {
  const { workspace } = useAppConstants();
  const { titan_emails_mrp } = workspace || {};

  const allDiscountPrices = [
    domainData?.discount,
    titan_emails_mrp,
    DOMAIN_CART_PRICES_CONSTANTS.PREMIUM_HOSTING,
    DOMAIN_CART_PRICES_CONSTANTS.SSL_ENCRYPTION,
  ];

  const totalDiscount = allDiscountPrices.reduce(
    (total, current) => total + (current || 0),
    0
  );

  return (
    <div
      className={
        "bg-green-offer w-fit pv5px ph12px br3 mb3 flex gap2 items-center justify-center " +
        className
      }
    >
      <GreatIcon />
      <span className="fw5 jakarta f13rem lh-solid-bold">
        Great! You’re saving{" "}
        <span className="fw7 jakarta">₹{totalDiscount}</span> on this order!
      </span>
    </div>
  );
};

export default DiscountOfferStrip;
