import React from "react";
import search from "../../Assets/search@2x.png";
import angleright from "../../Assets/angleright.png";
import closeIcon from "./../../Assets/close.png";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { logAndroid } from "../../Utils/androidEvents";
import { Modal } from "../../Components";
import { addToCart } from "../../Redux/Actions";

export default function ContactList(props) {
  const dispatch = useDispatch();
  const [contactData, handleContactData] = React.useState([]);
  const [phone, handlePhone] = React.useState("");

  const cartData = useSelector((state) => state.cartReducer.data);
  const appData = useSelector((state) => state.appReducer.data);
  const { bill_image } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );

  React.useEffect(() => {
    if (window.Android) {
      handleContactData(appData.contactList);
    } else {
      handleContactData(formatContactData(appData.contactList));
    }
    logAndroid(JSON.stringify("dqd" + JSON.stringify(appData.contactList)));
  }, []);

  React.useEffect(() => {
    if (window.Android) {
      handleContactData(appData.contactList);
    } else {
      handleContactData(formatContactData(appData.contactList));
    }
  }, [appData.contactList]);

  const formatContactData = (data) => {
    let arr = [];
    data &&
      Object.keys(data).length &&
      Object.keys(data).map((key) => {
        arr.push({ number: key, name: data[key].name });
      });
    return (
      arr &&
      arr.length &&
      arr.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        return 0;
      })
    );
  };

  const toggleModalVisible = () => {
    props.toggleModalVisible();
  };

  const handleNavigate = (val, fname) => {
    let newState = { ...cartData };
    newState["phone"] = val;
    newState["name"] = fname;
    dispatch(addToCart(newState));
    props.openContactConfirm();
  };

  const handleTextChange = (value) => {
    let newState = [];
    if (value) {
      appData.contactList &&
        Object.keys(appData.contactList).length > 0 &&
        Object.keys(appData.contactList).map((key) => {
          if (
            isNaN(value)
              ? appData.contactList[key] && appData.contactList[key].name &&
               appData.contactList[key].name
                  .toLowerCase()
                  .indexOf(value.toLowerCase()) != -1
              : key.indexOf(value) != -1
          ) {
            newState.push({ number: key, name: appData.contactList[key].name });
          }
        });
      handleContactData(newState);
      handlePhone(value);
    } else {
      handlePhone("");
      handleContactData(formatContactData(appData.contactList));
    }
  };

  const filterPhoneNumber = (value) => {
    let newState = [];
    if (value) {
      appData.contactList &&
        appData.contactList.length > 0 &&
        appData.contactList.map((item) => {
          if (
            isNaN(value)
              ? item.name.toLowerCase().indexOf(value.toLowerCase()) != -1
              : item.number.indexOf(value) != -1
          ) {
            newState.push({ number: item.number, name: item.name });
          }
        });
      handleContactData(newState);
      handlePhone(value);
    } else {
      handlePhone("");
      handleContactData(appData.contactList);
    }
  };

  const renderContactNumber = (name, mobileNum, fullname) => {
    return (
      <div
        className={"contact-item-wrap"}
        key={"cnct" + mobileNum}
        disabled={mobileNum.length < 10 || isNaN(mobileNum)}
        onClick={() =>
          mobileNum.length >= 10 && handleNavigate(mobileNum, fullname)
        }
      >
        <div className="inner-div">
          <div>{name}</div>
          <div>
            {fullname ? null : (
              <label className="green-txt">
                {bill_image?.contactlist_send_bill_to_text || "Send Bill to"}
              </label>
            )}
            <label>{fullname || mobileNum}</label>
          </div>
        </div>
        <img src={angleright} alt="" />
      </div>
    );
  };

  const regexTxt = /^[A-Za-z\s]/;

  return (
    <Modal
      visible={props.isVisible}
      className="bottom-modal contact-list-modal"
      height={200}
      animation={"slideUp"}
      onClose={toggleModalVisible}
    >
      <span className="modal-close-icon" onClick={toggleModalVisible}>
        <img src={closeIcon} />
      </span>
      <h1>
        {bill_image?.contactlist_enter_whatsapp_number_or_name_text ||
          "Enter WhatsApp Number or Name"}
      </h1>
      <div className="form-group">
        <input
          value={phone}
          onChange={(e) => {
            if (window.Android) {
              filterPhoneNumber(e.target.value.slice(0, 10));
            } else {
              handleTextChange(e.target.value.slice(0, 10));
            }
          }}
          // onChange={(e) => handleTextChange(e.target.value)}
          maxLength={20}
          type="text"
          placeholder={
            bill_image?.contactlist_placeholder_enter_name_or_number_text ||
            "Enter name or number"
          }
        />
        <img src={search} alt="" />
      </div>
      <div className="autocomplete-wrap">
        {contactData && contactData.length > 0 ? (
          <p style={{ color: "#868686", marginTop: 25 }}>
            {bill_image?.contactlist_or_pickup_from_contacts_text ||
              "or Pick from contacts"}
          </p>
        ) : null}
        {contactData && contactData.length > 0
          ? contactData.map((contact, index) => {
              return renderContactNumber(
                contact && contact.name && regexTxt.test(contact.name)
                  ? contact.name.split("")[0] + contact.name.split("")[1]
                  : "#",
                contact.number,
                contact.name
              );
            })
          : phone && phone.length > 2 && !isNaN(phone)
          ? renderContactNumber("#", phone)
          : null}
      </div>
    </Modal>
  );
}
