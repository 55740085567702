import React, { useEffect, useState } from "react";
import angleRight from "./../../Assets/downaccordion.png";
import "./style.scss";

const Accordion = (props) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const arrow = document.getElementById(`${props.id}-right`);
    if (arrow) {
      open
        ? (arrow.style.transform = "rotate(180deg)")
        : (arrow.style.transform = "rotate(0deg)");
    }
  }, []);

  const toggleAccordian = () => {
    const element = document.getElementById(props.id);
    const arrow = document.getElementById(`${props.id}-right`);
    if (element && arrow) {
      if (open) {
        element.style.maxHeight = "0";
        arrow.style.transform = "rotate(0deg)";
      } else {
        element.style.maxHeight = "100%";
        arrow.style.transform = "rotate(180deg)";
      }
      setOpen((prev) => !prev);
    }
  };

  return (
    <article className="tab">
      <div className="tab-label flex items-center" htmlFor={props.id}>
        {props.startIcon && <div className="tab-left-ic">{props.startIcon}</div>}
        <div className="tab-label-wrap" onClick={toggleAccordian}>{props.title}</div>
        <div className="tab-ic" onClick={toggleAccordian}>
          <img src={angleRight} id={`${props.id}-right`} />
        </div>
      </div>
      <div className="tab-content" id={props.id}>
        {props.children}
      </div>
    </article>
  );
};

export default Accordion;
