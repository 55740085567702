import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import history from "../../Utils/history";
import { emptyDisplayThems, fetchDisplayThemes } from "../../Redux/Actions";
import { trackAppEvents } from "../../Utils/androidEvents";

export default function ExploreThemeByColorCategories(props) {
  const dispatch = useDispatch();

  const { themeData } = useSelector((state) => ({
    themeData: state.themesReducer.displayTags,
  }));

  const urlParams = new URLSearchParams(window.location.search);
  const storeid = urlParams.get("storeid");
  const token = urlParams.get("token");
  const additionalParameters = `?token=${token}&storeid=${storeid}`;

  const getdisplayThemes = (id) => {
    dispatch(emptyDisplayThems());
    props.setPageNumber1 && props.setPageNumber1();
    const payload = {
      type: history.location.pathname.includes("color") ? "color" : "tag",
      id,
      page: 1,
    };
    dispatch(fetchDisplayThemes(payload));
  };

  return (
    <div className="explore-wrap">
      {!history.location.pathname.includes("/theme-search/businesstype") &&
      themeData?.businessTypes &&
      themeData.businessTypes.length > 0 ? (
        <>
          <h3>Explore by categories</h3>
          <ul className="explore-wrap-category">
            {themeData.businessTypes.map((item) => {
              return (
                <li
                  onClick={() => {
                    trackAppEvents("View_Themes", {
                      storeid: storeid,
                      channel: `Explore`,
                      name: item.name,
                      id: item.tag_id,
                    });
                    history.push(
                      `/themes/category/${item.tag_id}${additionalParameters}`,
                      {
                        heading: item.name,
                      }
                    );
                    getdisplayThemes(item.tag_id);
                    window.scrollTo(0, 0);
                  }}
                >
                  <div
                    className="item"
                    style={{
                      background: `url(${item.image_url})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <span>{item.name}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : null}

      {themeData?.colors && themeData.colors.length > 0 ? (
        <>
          <h3>Explore by color</h3>
          <ul className="explore-wrap-color">
            {themeData.colors.map((item) => {
              return (
                <li
                  onClick={() => {
                    history.push(
                      `/themes/color/${item.tag_id}${additionalParameters}`,
                      {
                        heading: "Browse by color",
                      }
                    );
                    getdisplayThemes(item.tag_id);
                    window.scrollTo(0, 0);
                  }}
                >
                  <div
                    className="item"
                    style={{
                      background: `url(${item.image_url})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <span>{item.name}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : null}
    </div>
  );
}
