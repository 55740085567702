import React from 'react';
import './style.scss';
import closeIcon from './../../Assets/close.png';

const YoutubePopUp = ({ onClose, videoSrc }) => {
  return (
    <div className="youtubePopup">
      <span 
        onClick={onClose}
        className="youtubePopup__close">
        <img src={closeIcon}/>
      </span>
      <iframe
        id={`youtube-iframeVideo`}
        width="100%"
        height="230"
        src={videoSrc}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowfullscreen"
      ></iframe>
    </div>
  )
}

export default YoutubePopUp;