import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import MailIcon from "../../../../Assets/mail-icon-solid-white.svg";
import OrangeInfo from "../../../../Assets/outlined-info-icon-orange.svg";
import { mailToDotpeExpert } from "../../../../Utils/common";
import "./style.scss";

const BannerAfterExpiry = (props) => {
  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.data.store,
  }));

  return (
    <section className="banner-after-expiry">
      <div className="flex items-center mb2">
        <img src={OrangeInfo} />
        <h3>{props.isEmailStatusOutOfBounds ? "Oops! An error occured" : "Your professional email(s) are expired!"}</h3>
      </div>
      <p>Please contact Customer Support to activate your Google workspace account</p>
      <div className="flex justify-end">
        <button className="contact-btn br-pill flex items-center" onClick={() => mailToDotpeExpert(storeData)}>
          <img src={MailIcon} />
          Contact Us
        </button>
      </div>
    </section>
  )
}

export default React.memo(BannerAfterExpiry);