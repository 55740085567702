import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "..";
import LazyPayAuth from "./lazyPayAuth";

const LazyPayAuthModal = (props) => {
  const { lazyPayAuthModal } = useSelector((state) => ({
    lazyPayAuthModal: state.commonReducer.lazyPayAuthModal,
  }));
  return (
    <Modal
      visible={lazyPayAuthModal?.show}
      className="bottom-modal"
      animation={"slideUp"}
      onClose={props.onClose}
      height={"auto"}
    >
      <LazyPayAuth onClose={props.onClose} />
    </Modal>
  );
};

export default LazyPayAuthModal;
