import React, { useContext } from "react";
import "./style.scss";
import domainImg from "../../Assets/searchDomain.png";
import history from "../../Utils/history";
import { trackAppEvents } from "../../Utils/androidEvents";
import { ParentContext } from "../../parentProvider";

const PremiumDomainHook1 = (props) => {
  const contextData = useContext(ParentContext);

  const { additionalParameters, token, storeid } = contextData;

  return (
    <article className="premiumDomainHook1">
      <div className="premiumDomainHook1__title">
        Get <strong>Your</strong>{" "}
      </div>
      <div className="premiumDomainHook1__title--sec">Perfect Store Link</div>
      <div className="premiumDomainHook1__desktop">
        <img
          className="premiumDomainHook1__desktop--img"
          src={domainImg}
          alt="premium device img icon"
        />
      </div>
      <button
        onClick={() => {
          trackAppEvents("Domain_Explore", {
            channel: "isPremiumPage",
          });
          history.push(`/domain-explore${additionalParameters}`);
        }}
        className="premiumDomainHook1__getThemeBtn"
      >
        Get Custom Domain
      </button>
    </article>
  );
};

export default PremiumDomainHook1;
