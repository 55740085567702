import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "..";
import {
  fetchCatalogCategories,
  fetchCatalogItemsBasicDetailsByCategoryId,
} from "../../Redux/Actions";
import closeIcon from "./../../Assets/close.png";
import "./styles.scss";

export default function ProductListPopUp(props) {
  const dispatch = useDispatch();
  const { categories, catalog_items } = useSelector(
    (state) => state.catalogReducer
  );
  const { product_promotion } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );
  const [phone, handlePhone] = React.useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");
  const [catalogStateData, handleCatalogStateData] = React.useState([]);
  const [searchTxt, handleSearchTxt] = React.useState("");

  useEffect(() => {
    if (store_id && categories && categories.length === 0)
      dispatch(fetchCatalogCategories(store_id));

    let tempCatalogItems = Object.entries(catalog_items);
    if (tempCatalogItems && tempCatalogItems.length > 0) {
      if (props.productPromotion) {
        tempCatalogItems = tempCatalogItems.reduce((acc, curr) => {
          if (curr[1] && Array.isArray(curr[1])) {
            curr[1] = curr[1].filter(
              (item) => item.price > item.discounted_price
            );
            acc.push(curr);
          }
          return acc;
        }, []);
      }

      handleCatalogStateData(getFormatedCatalogData(tempCatalogItems));
    }
  }, [categories, catalog_items]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      categories.forEach((category) => {
        dispatch(
          fetchCatalogItemsBasicDetailsByCategoryId({
            store_id: store_id,
            category,
          })
        );
      });
    }
  }, [categories]);

  const getFormatedCatalogData = (catalogData) => {
    return catalogData.map(([categoryId, items]) => {
      return {
        category: categories.find((el) => el.id === Number(categoryId)),
        items: items,
      };
    });
  };

  const handleSelectedProduct = (item) => {
    props.handleSelectedProduct(item);
  };

  const renderItem = (item) => {
    return (
      item &&
      item.image_url && (
        <div
          className="product-item"
          onClick={() => handleSelectedProduct(item)}
          key={`catalog-product-${item.id}`}
        >
          <div className="left">
            <img src={item.image_url} alt="" />
          </div>
          <div className="right">
            <label>{item.name}</label>
            <p className="price">
              {item.discounted_price < item.price ? (
                <>
                  <span className="discounted-price">
                    {"₹"}
                    {item.price}
                  </span>{" "}
                  {"₹"}
                  {item.discounted_price}
                </>
              ) : (
                "₹" + item.discounted_price
              )}{" "}
            </p>
          </div>
        </div>
      )
    );
  };

  const handleTextChange = (value) => {
    const catalogData = getFormatedCatalogData(Object.entries(catalog_items));
    handleSearchTxt(value);
    if (value) {
      let filterData =
        catalogData &&
        catalogData.length &&
        catalogData.map((catalog) => {
          const myItem =
            catalog?.items &&
            catalog?.items.length &&
            catalog?.items
              .map((item) => {
                if (
                  item?.name.toLowerCase().indexOf(value.toLowerCase()) != -1
                ) {
                  return Object.assign({}, item);
                }
              })
              .filter((e) => e);
          return Object.assign({ ...catalog }, { items: myItem });
        });
      handleCatalogStateData(filterData);
    } else {
      handleCatalogStateData(catalogData);
    }
  };

  return (
    <Modal
      visible={props.isModalVisible}
      className="bottom-modal product-promotion-modal"
      height={200}
      animation={"slideUp"}
      onClose={props.toggleModalVisible}
    >
      <span className="modal-close-icon" onClick={props.toggleModalVisible}>
        <img src={closeIcon} />
      </span>
      <h1>
        <span style={{ color: "#EA5151" }}>
          {product_promotion?.please_note_text || "Please note:"}
        </span>
        {product_promotion?.only_product_with_images_are_shown_here_text ||
          "Only products with images are shown here"}
      </h1>
      <div
        className="search-wrap
      "
      >
        <input
          type="text"
          placeholder={
            product_promotion?.placeholder_search_products_text ||
            "Search Products"
          }
          onChange={(e) => handleTextChange(e.target.value.slice(0, 10))}
        />
      </div>
      <div className="category-product-wrap">
        {catalogStateData &&
          catalogStateData.length > 0 &&
          catalogStateData.map((item) => {
            return (
              <div
                className="cat-item"
                key={`category-items-${item?.category?.id}`}
              >
                {item?.items &&
                !!item?.items.length &&
                item?.items.length > 0 ? (
                  <p className="cat-heading">
                    {item?.category?.name || "Available Items"}
                  </p>
                ) : null}
                {item?.items &&
                  item?.items.length > 0 &&
                  item?.items.map((pitem) => {
                    return renderItem(pitem);
                  })}
              </div>
            );
          })}
      </div>
    </Modal>
  );
}
