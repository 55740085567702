import React, { useState, useEffect } from "react";
import "./styles.scss";
import { getAdStatus } from "./../../Utils/common";

const AdStatusIcon = ({ adData }) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    const status = getAdStatus(adData?.status);
    setState(status);
  }, [adData]);

  return (
    <span className="adStatusIcon">
      <article className="status-label">
        <div className={`${state?.class}-bg`}></div>
        <div className={state?.class}>{state?.text}</div>
      </article>
    </span>
  );
};

export default AdStatusIcon;
