import moment from "moment";

export const getTimeDateDiff = (
  startDate,
  endDate,
  type //  'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years'
) => {
  const currentStartDate = startDate ? moment(startDate) : moment();
  const currentEndDate = endDate ? moment(endDate) : moment();
  const currentDiff = currentEndDate.diff(currentStartDate, type);
  return currentDiff;
};
