import React, { useEffect, useState } from "react";
import tickIcon from "./../../Assets/checkmark-green-dark.png";
import ArrowDark from "./../../Assets/Arrow-Dark.png";
import "./style.scss";

const StepAccordion = (props) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const element = document.getElementById(props.id);
    const arrow = document.getElementById(`${props.id}-right`);
    if (element && arrow) {
      if (props.open) {
        element.style.display = "block";
        arrow.style.transform = "rotate(180deg)";
      } else {
        element.style.display = "none";
        arrow.style.transform = "rotate(0deg)";
      }
    }
  }, [props.open]);

  const toggleAccordian = () => {
    // const element = document.getElementById(props.id);
    // const arrow = document.getElementById(`${props.id}-right`);
    // if (element && arrow) {
    //   if (open) {
    //     element.style.display = "none";
    //     arrow.style.transform = "rotate(0deg)";
    //   } else {
    //     element.style.display = "block";
    //     arrow.style.transform = "rotate(180deg)";
    //   }
    //   setOpen((prev) => !prev);
    // }
    props.onClick && props.onClick();
  };

  return (
    <article className="stepaccordian">
      <div
        className="stepaccordian-pane"
        htmlFor={props.id}
        onClick={toggleAccordian}
      >
        {props.image && (
          <div className="stepaccordian-pane__logo">
            <img src={props.image} alt="tab icon" />
          </div>
        )}

        <div className="stepaccordian-pane__content">
          <p className="stepaccordian-pane__content--title">
            <span className="title-text">{props.title}</span>
            {props.completed && (
              <>
                <span className="title-text">|</span>
                <span className="completed-text">
                  Completed <img src={tickIcon} />
                </span>
              </>
            )}
          </p>
          <p className="stepaccordian-pane__content--subtitle">
            {props.subtitle}
          </p>
        </div>
        <div className="stepaccordian-pane__anchor">
          <img src={ArrowDark} id={`${props.id}-right`} />
        </div>
      </div>
      <div className="stepaccordian-content" id={props.id}>
        {props.children}
      </div>
    </article>
  );
};

export default StepAccordion;
