import React from "react";
import Modal from "../Modal";
import history from "../../Utils/history";
import "./style.scss";

const DomainTakenModal = ({ show, onClose, domainName }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const storeid = urlParams.get("storeid");
  const token = urlParams.get("token");
  const additionalParameters = `?token=${token}&storeid=${storeid}`;

  const onSearchAnotherBtnHandle = () => {
    history.push(`/domain-search${additionalParameters}`);
  };

  return (
    <Modal
      visible={show}
      className="bottom-modal DomainTakenModal"
      height={200}
      animation={"slideUp"}
      onClose={onClose}
    >
      <div className="DomainTakenModal__title">Domain is taken!</div>
      <div className="DomainTakenModal__info">
        <article className="DomainTakenModal__info--domain">
          {domainName}
        </article>
        <article className="DomainTakenModal__info--message">
          Sorry, this domain is already taken
        </article>
      </div>
      <div className="DomainTakenModal__bottom">
        <button
          className="DomainTakenModal__bottom--btn"
          onClick={onSearchAnotherBtnHandle}
        >
          Search another
        </button>
      </div>
    </Modal>
  );
};

export default DomainTakenModal;
