import React from "react";
import "./style.scss";
import Modal from "./../Modal";
import closeIcon from "./../../Assets/close.png";
import { useSelector } from "react-redux";

const ThemePricePreviewModal = (props) => {
  const { subscriptionDetails } = useSelector((state) => state.themesReducer);

  return (
    <Modal
      visible={props.show}
      className="bottom-modal pre-themePricePriview-modal"
      //   height={400}
      animation={"slideUp"}
      onClose={props.toggleModal}
    >
      <span className="modal-close-icon" onClick={props.toggleModal}>
        <img src={closeIcon} />
      </span>
      <section className="title">Price Details</section>
      <section className="main">
        <article className="amount-item">
          <div>Theme #{props.id} (upgrade)</div>
            <div>₹{props.price}</div>
        </article>
        <article className="amount-item">
          <div>You have already paid</div>
            <div>- ₹{subscriptionDetails.data.price}</div>
        </article>
      </section>
      <section className="totol-section">
        <article className="amount-item">
          <div>Payable Amount</div>
            <div>₹{props.price - subscriptionDetails.data.price}</div>
        </article>
      </section>

      <button 
        className="apply-now _220jKJ" 
        onClick={props.applyNow}>
        Apply Now
      </button>
    </Modal>
  );
};

export default ThemePricePreviewModal;
