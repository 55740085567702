import axios from "axios";
import config from "../Config";
import { ToastNotifyError } from "../Components/Toast";
import { v4 as uuidv4 } from "uuid";
import { CONSTANTS, getLocalState, getSessionState } from "../Utils/storage";
import { getUrlParams } from "../Utils/getUrlParams";
import { URL_PARAMS } from "../Utils/constants";

if (!sessionStorage.getItem("sess-id")) {
  let newSessId = uuidv4();
  sessionStorage.setItem("sess-id", newSessId);
}
const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const api = axios.create(defaultOptions);

api.interceptors.request.use((item) => {
  return item;
});

api.interceptors.request.use((item) => {
  const sessid = sessionStorage.getItem("sess-id");
  item.headers["session_id"] = sessid ? `${sessid}` : "";
  if (item.url.includes("dotk")) {
    const [tokenFromUrl] = getUrlParams([URL_PARAMS.TOKEN]);
    const tokenFromLocal = getLocalState(CONSTANTS.LOCAL.TOKEN);
    const temporaryToken = getLocalState(CONSTANTS.LOCAL.TEMP_TOKEN);
    const tokenFromSession = getLocalState(CONSTANTS.LOCAL.TOKEN);
    item.headers["auth_token"] =
      window.auth_token ||
      tokenFromUrl ||
      tokenFromLocal ||
      tokenFromSession ||
      temporaryToken;
    item.headers["app_os"] = "webview";
    item.headers["app_version"] = "1";
  }
  return item;
});

api.interceptors.response.use(
  (resp) => {
    if (resp?.config?.parseToJson && resp?.data?.data) {
      resp["data"]["data"] = JSON.parse(resp?.data?.data);
    }

    return resp;
  },
  (err) => {
    const { data, status, response } = err?.response;
    console.log(err);
    ToastNotifyError(data?.message || `Something went bad!`);

    throw err.response;
  }
);

export default api;
