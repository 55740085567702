import React from "react";
import { useState } from "react";
import "./style.scss";
import BlueArrow from "../../../Assets/blue-arrow.svg"
import BlackArrow from "../../../Assets/Arrow-Dark.png";
import { CURRENCY_SYMBOL } from "../../../Config";

const WorkspaceAdvancedPaymentModal = (props) => {
  const [expandTop, setExpandTop] = useState(false);

  const toggleTop = () => setExpandTop(!expandTop)

  return (
    <div className="workspace-advanced-payment-modal">
      {expandTop ? (
        <>
          <div>
            <h3 className="flex items-center main-header" onClick={toggleTop}>
              Price Details <img className="price-arrow ml2" src={BlackArrow} />
            </h3>
          </div>
          {(props.workspacePrice && Object.keys(props.workspacePrice).length) ? (
            <div className="middle-component">
              <div className="flex justify-between items-center">
                <span className="panel-text">Price of {props?.userList?.length} account(s)</span>
                <span className="panel-text">{CURRENCY_SYMBOL}{props?.workspacePrice?.mrp}/-</span>
              </div>

              <div className="flex justify-between items-center">
                <span className="panel-text">GST ({props?.workspacePrice?.gst_percent}%):</span>
                <span className="panel-text">{CURRENCY_SYMBOL}{props?.workspacePrice?.gst_value}/-</span>
              </div>
              {props?.workspacePrice?.discount_value ? (
                <div className="flex justify-between items-center">
                  <span className="panel-text"></span>
                  <span className="panel-text discount-text">{CURRENCY_SYMBOL}{props?.workspacePrice?.discount_value}/-</span>
                </div>
              ) : null}
              <div className="flex justify-between items-center total-section">
                <span className="panel-text">Total Amount</span>
                <span className="panel-text">{CURRENCY_SYMBOL}{props?.workspacePrice?.total_price}/-</span>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <div className="flex items-center justify-between flex-row-reverse">
        <button className="pay-btn br-pill" onClick={props.payNowBtnClick} disabled={props.buttonDisabled}>Pay {CURRENCY_SYMBOL}{props?.workspacePrice?.total_price}/-</button>
        {!expandTop ? (
          <div className="price-details" onClick={toggleTop}>
            <span className="mr2">View price details</span> <img src={BlueArrow} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default WorkspaceAdvancedPaymentModal