import React from "react";
import "./styles.scss";
import { Modal } from "..";
import { DIGITAL_SHOWROOM_OS_DATA } from "../../Utils/constants";
import { sendDataToApp } from "../../Utils/androidEvents";

export default function ForceAppUpdateModal(props) {
  const { isVisible, closeModal } = props;

  const forceUpdate = () => {
    closeModal();
    window.Android &&
      sendDataToApp({
        redirectBrowser: true,
        data: "https://play.google.com/store/apps/details?id=com.digitaldukaan",
      });

    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          openAppByPackage: true,
          id: DIGITAL_SHOWROOM_OS_DATA.iosAppId,
        })
      );
  };

  return (
    <Modal
      visible={isVisible}
      className="bottom-modal force-app-update-modal-wrapper"
      onClose={closeModal}
      animation={"enter"}
      showCloseButton={true}
      height="200px"
    >
      <p className="heading-small mb2">Update required</p>
      <h1 className="heading-large mt1 mb3">
        Please update your app to enjoy all new features
      </h1>
      <button
        className="bg-black white bn pv3 f16px mt3 mb0 w-100 br-pill"
        onClick={forceUpdate}
      >
        Update Now
      </button>
    </Modal>
  );
}
