import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "..";
import pencil from "../../Assets/pencil.png";
import closeIcon from "./../../Assets/close.png";
import { addToCart, createOrder } from "../../Redux/Actions";
import "./styles.scss";
import { getCartItemsWithGst } from "../../Utils/common";

export default function ContactList(props) {
  const dispatch = useDispatch();
  const [isInputAmtVisible, handleInputAmtVisibility] = React.useState(false);

  const { cart } = useSelector(
    (state) => state.localizationReducer.localizedTxts
  );
  const cartData = useSelector((state) => state.cartReducer.data);
  const isLoaderVisible = useSelector(
    (state) => state.loaderReducer.isLoaderVisible
  );

  const toggleModalVisible = () => {
    props.toggleModalVisible();
  };

  const toggleInputVisibility = () => {
    handleInputAmtVisibility(!isInputAmtVisible);
  };

  const handleChange = (val) => {
    let newState = { ...cartData };
    if (val) {
      if ("pay_amount" in newState) {
        newState["pay_amount"] = parseFloat(val);
      } else {
        newState["amount"] = parseFloat(val);
      }
      dispatch(addToCart(newState));
    } else {
      if ("pay_amount" in newState) {
        newState["pay_amount"] = parseFloat(0);
      } else {
        newState["amount"] = parseFloat(0);
      }
      dispatch(addToCart(newState));
    }
  };

  const parseCartItemsForAdditionalInfo = (tempData) => {
    const data = tempData.items.reduce(
      (acc, curr) => {
        if (curr.item_type === "charge" && curr.item_name === "Delivery") {
          acc.delivery_charge = curr.discounted_price;
        } else if (
          curr.item_type === "charge" &&
          curr.item_name === "Discount"
        ) {
          acc.discount = curr.discounted_price;
        } else if (
          curr.item_type === "charge" &&
          curr.item_name !== "Delivery" &&
          curr.item_name !== "Discount"
        ) {
          acc.extra_charges = curr.discounted_price;
          acc.extra_charge_name = curr.item_name;
        }
        return acc;
      },
      {
        extra_charges: null,
        extra_charge_name: null,
        discount: null,
        delivery_charge: null,
      }
    );
    return data;
  };

  const parseCartItems = (tempData) => {
    const data = [];
    tempData.items.map((item) => {
      if (item.item_type !== "charge") {
        const newItem = {
          ...item,
          actual_price: item.item_price,
          amount: item.item_quantity * item.discounted_price,
          quantity: Number(item.item_quantity),
        };
        data.push(newItem);
      }
    });
    return data;
  };

  const handleCreateOrder = (val) => {
    const extraValues = parseCartItemsForAdditionalInfo(cartData);
    const parsedCartItems = parseCartItems(cartData);
    const cartItemsWithGst = getCartItemsWithGst(parsedCartItems);
    const urlParams = new URLSearchParams(window.location.search);
    const store_id = urlParams.get("storeid");
    const newCartData = {
      ...cartData,
      items: cartItemsWithGst,
      ...extraValues,
      store_id: Number(store_id),
      order_type: 3,
      order_mode: 0,

    };
    dispatch(createOrder(newCartData));
  };

  return (
    <Modal
      visible={props.isVisible}
      className="bottom-modal contact-confirm-modal"
      height={200}
      animation={"slideUp"}
      onClose={toggleModalVisible}
    >
      <span className="modal-close-icon" onClick={toggleModalVisible}>
        <img src={closeIcon} />
      </span>
      <h1>{cart?.contact_confirm_please_confirm_text || "Please confirm"}</h1>
      <div className="confirm-item border">
        <label>
          {cartData.name
            ? cart?.contact_confirm_cutomer_details_text || "Customer Details"
            : cart?.contact_confirm_customer_whatsapp_no_text ||
              "Customer WhatsApp Number"}
        </label>
        <div className="number-wrap">
          <div>
            <label>{cartData.name}</label>
            <label>{cartData.phone}</label>
          </div>
          <div>
            <img onClick={() => props.openContactModal()} src={pencil} alt="" />
          </div>
        </div>
      </div>
      <div className="confirm-item">
        <label>{cart?.contact_confirm_bill_amount_text || "Bill Amount"}</label>
        <div className="number-wrap">
          {isInputAmtVisible ? (
            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={
                  cartData.pay_amount || cartData.pay_amount === 0
                    ? cartData.pay_amount
                    : cart.amount
                    ? cart.amount
                    : ""
                }
                onChange={(e) => handleChange(e.target.value)}
              />
              <label
                style={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  fontSize: 16,
                }}
              >
                ₹
              </label>
            </div>
          ) : (
            <label className="amt-confirm">
              ₹
              {cartData.pay_amount || cartData.pay_amount === 0
                ? cartData.pay_amount
                : cart.amount
                ? cart.amount
                : ""}
            </label>
          )}
          {props?.isFromCart ? (
            ""
          ) : (
            <div>
              <img
                onClick={() => toggleInputVisibility()}
                src={pencil}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <button
        disabled={isLoaderVisible}
        onClick={() => handleCreateOrder()}
        className="sendbill-btn"
      >
        {isLoaderVisible ? "Loading.." : cart?.send_bill_text || "Send Bill"}
      </button>
    </Modal>
  );
}
