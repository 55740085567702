import React, { useContext } from "react";
import crown from "../../Assets/crown.png";
import desktop from "../../Assets/desktop.png";
import history from "../../Utils/history";
import customIcon from "./../../Assets/micIcon-cirlce.png";
import handIcon from "./../../Assets/ic_onetime@2x.png";
import removeIcon from "./../../Assets/remove_shop_icon.png";
import "./styles.scss";
import { trackAppEvents } from "../../Utils/androidEvents";
import { ParentContext } from "../../parentProvider";

export default function PremiumThemeHook2() {
  const contextData = useContext(ParentContext);

  const { additionalParameters, token, storeid } = contextData;

  return (
    <div className="premium-theme-hook-xl">
      <div className="top-wrap">
        <img src={desktop} alt="" />
      </div>
      <div className="left-wrap">
        <h3 className="animate__fadeIn animate__animated">
          Get{" "}
          <div>
            Premium
            <img src={crown} alt="" />
          </div>{" "}
          website for your Showroom
        </h3>
        <div className="scroller-section animate__fadeInUp animate__animated">
          <div className="Marquee">
            <div className="Marquee-content">
              <div className="Marquee-tag">
                <article>
                  <img src={handIcon} alt="" />
                  <span>
                    {" "}
                    Huge library of <br></br>Premium Themes
                  </span>
                </article>
              </div>
              <div className="Marquee-tag">
                <article>
                  <img src={customIcon} alt="" />
                  <span>
                    Dedicated <br></br> Support
                  </span>
                </article>
              </div>
              <div className="Marquee-tag">
                <article>
                  <img src={removeIcon} alt="" />
                  <span>
                    Remove Digital <br></br>Showroom Branding
                  </span>
                </article>
              </div>
              <div className="Marquee-tag">
                <article>
                  <img src={handIcon} alt="" />
                  <span>
                    {" "}
                    Huge library of <br></br>Premium Themes
                  </span>
                </article>
              </div>
              <div className="Marquee-tag">
                <article>
                  <img src={customIcon} alt="" />
                  <span>
                    Dedicated<br></br> Support
                  </span>
                </article>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            trackAppEvents("Get_Premium_Website", {
              channel: "isPremiumPage",
            });
            history.push(`/theme-explore${additionalParameters}`);
          }}
        >
          Get Premium website
        </button>
      </div>
    </div>
  );
}
