import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ParentContext } from "../../../parentProvider";
import { doesStoreAddressExist } from "../../../Utils/common";
import {
  ROUTES,
  WORKSPACE_PLAN_TYPE,
  WORKSPACE_USER_STATUS,
} from "../../../Utils/constants";
import history from "../../../Utils/history";
import "./style.scss";
import { getUrlParams } from "../../../Utils/getUrlParams";

const AddNewUserButton = (props) => {
  const contextData = useContext(ParentContext);
  const { additionalParameters } = contextData;
  const [domain] = getUrlParams(["domain"]);

  const { workspaceInfo, workspacePrimaryUser, storeData } = useSelector(
    (state) => ({
      workspaceInfo: state.workspaceReducer.workspaceInfo,
      workspacePrimaryUser: state.workspaceReducer.workspacePrimaryUser,
      storeData: state.storeReducer.data.store,
    })
  );

  const checkIfPrimaryAccExist = () =>
    workspacePrimaryUser && Object.keys(workspacePrimaryUser).length > 0;

  const primaryAccountStatusZeroOrFour =
    workspacePrimaryUser.status === WORKSPACE_USER_STATUS.IN_PROGRESS ||
    workspacePrimaryUser.status === WORKSPACE_USER_STATUS.PAYMENT_RECEIVED;

  const onClickGetNewEmailsCTAClick = () => {
    if (!doesStoreAddressExist(storeData)) {
      props.toggleAddressModal();
      return;
    }

    const workspacePlanType = workspaceInfo?.plan_type;

    if (
      workspacePlanType ===
        WORKSPACE_PLAN_TYPE.WORKSPACE_PLAN_HALF_YEAR_FLEXI ||
      workspacePlanType === WORKSPACE_PLAN_TYPE.WORKSPACE_PLAN_QUARTER_FLEXI
    ) {
      props.toggleGenericWorkSpaceModal();
    } else {
      if (checkIfPrimaryAccExist() && !primaryAccountStatusZeroOrFour) {
        // reroute to new setup link
        history.push(
          `${ROUTES.WORKSPACE_CREATE_ACCOUNT_AFTER_INITIAL}${additionalParameters}&domain=${domain}`
        );
      } else {
        props.getWorkspacePriceForNewEmails();
      }
    }
  };

  return (
    <section
      className="get-new-email-btn-card flex justify-center items-center bg-white"
      onClick={onClickGetNewEmailsCTAClick}
    >
      <p className="black f14px mv0">+ Add new user(s)</p>
    </section>
  );
};

export default AddNewUserButton;
