import { ToastNotifyError, ToastNotifySuccess } from "../Components/Toast";
import { sendDataToApp } from "./androidEvents";

export function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    ToastNotifySuccess("Copied successfully!");
  } catch (err) {
    ToastNotifyError("Couldn't copy, try again!");
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (window.Android) {
    sendDataToApp({
      copyToClipboard: true,
      text,
    });
    return;
  }
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      ToastNotifySuccess("Copied successfully!");
    },
    function (err) {
      ToastNotifyError("Couldn't copy, try again!");
    }
  );
}
