import { combineReducers } from "redux";
import loaderReducer from "./Loader";
import templatesReducer from "./templates";
import rewardsReducer from "./rewards";
import catalogReducer from "./catalog";
import cartReducer from "./cart";
import appReducer from "./appEssentials";
import orderReducer from "./order";
import storeReducer from "./store";
import commonReducer from "./common";
import creativesReducer from "./creatives";
import localizationReducer from "./localization";
import themesReducer from "./themes";
import paymentReducer from "./payment";
import domainReducer from "./domain";
import adsReducer from "./ads";
import workspaceReducer from "./workspace";
import referralProgramReducer from "./referral";
import couponReducer from './coupon';

const rootReducer = combineReducers({
  loaderReducer,
  templatesReducer,
  rewardsReducer,
  catalogReducer,
  cartReducer,
  appReducer,
  orderReducer,
  storeReducer,
  commonReducer,
  creativesReducer,
  localizationReducer,
  themesReducer,
  paymentReducer,
  domainReducer,
  adsReducer,
  workspaceReducer,
  referralProgramReducer,
  couponReducer,
});
export default rootReducer;
