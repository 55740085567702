import React from "react";
import search from "../../Assets/search@2x.png";
import angleright from "../../Assets/angleright.png";
import closeIcon from "./../../Assets/close.png";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import history from "../../Utils/history";
import { Modal } from "..";
import { addToCart } from "../../Redux/Actions";
import "./styles.scss";
export default function CategoryList(props) {
  const cartData = useSelector((state) => state.cartReducer.data);
  const appData = useSelector((state) => state.appReducer.data);
  const dispatch = useDispatch();
  const [contactData, handleContactData] = React.useState([]);
  const { categories } = useSelector((state) => state.catalogReducer);

  const handleCatClick = (id) => {
    props.toggleModalVisible();
    let groupNameEle = document.getElementById("product-wrap" + id);
    if (groupNameEle) {
      groupNameEle.scrollIntoView(true);
      window.scrollBy(0, 0);
    }
  };

  return (
    <Modal
      visible={props.isVisible}
      className="bottom-modal category-list-modal"
      height={200}
      animation={"slideUp"}
      onClose={props.toggleModalVisible}
    >
      <span className="modal-close-icon"
        onClick={props.toggleModalVisible}>
        <img src={closeIcon}/>
      </span>
      <div className="category-list-modal__content">
        <ul>
          {categories && categories.length
            ? categories.map((category, idx) => (
                <li
                  key={`${idx}-category-list`} 
                  onClick={() => handleCatClick(category.id)}>
                  {category.name || "Available Items"}
                </li>
              ))
            : null}
        </ul>
      </div>
    </Modal>
  );
}
