import React, { useContext, useEffect, useState } from "react";
import { LinearProgress, Modal } from "../..";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import { getWorkspaceDetails } from "../../../Redux/Actions";
import { ROUTES, WORKSPACE_USER_STATUS } from "../../../Utils/constants";
import { checkForUnAccountedStatusOfEmails } from "../../../Utils/workspaceUtilities";
import { useHistory } from "react-router-dom";
import { ParentContext } from "../../../parentProvider";

let timer = null;

const WorkspaceEmailProgressionModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const contextData = useContext(ParentContext);
  const { additionalParameters } = contextData;
  const [progress, setProgress] = useState(10);

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.data.store,
  }));

  useEffect(() => {
    if (props.isVisible && props.currentUsersCreated.length) {
      /*
        when modal opens, set an interval call for all
        workspace user list. then check all progress from that
      */
      setIntervalCallsForNonZeroEmailStatus();
    }

    return () => {
      clearIntervalCalls();
    };
  }, [props?.isVisible, props.currentUsersCreated.length]);

  const clearIntervalCalls = () => timer && clearInterval(timer);

  const setIntervalCallsForNonZeroEmailStatus = () => {
    timer = setInterval(() => {
      dispatch(
        getWorkspaceDetails(
          {
            text: storeData?.domain,
          },
          workspaceCallback,
        ),
      );
    }, 10000);
  };

  const workspaceCallback = (flag, workspaceUserList) => {
    // if any user gets status >= 5 or <= 15, redirect to main page
    if (checkForUnAccountedStatusOfEmails(workspaceUserList) || !flag) {
      history.replace(`${ROUTES.WORKSPACE_MANAGER}${additionalParameters}`);
    } else {
      // calculate progress percentage (out of 100)
      const usersWithStatusInProgress = workspaceUserList.filter(
        (user) => user.status === WORKSPACE_USER_STATUS.IN_PROGRESS,
      );
      const numOfUsersWithStatusInProgress = usersWithStatusInProgress.length;

      const progressValue = Math.floor(
        ((props.currentUsersCreated.length - numOfUsersWithStatusInProgress) / props.currentUsersCreated.length) * 100,
      );
      if (progressValue < 100) setProgress(progressValue === 0 ? 10 : progressValue);

      if (progressValue >= 100) {
        setProgress(100);
        clearIntervalCalls();
        setTimeout(() => {
          history.push(`${ROUTES.WORKSPACE_MANAGER}${additionalParameters}`, {
            showNewlyCreatedEmails: true,
            numOfNewlyCreatedEmails: props.currentUsersCreated.length,
          });
        }, 2000);
      }
    }
  };
  
  return (
    <Modal
      visible={props?.isVisible}
      className="centered-modal email-progression-modal"
      onClose={props?.closeModal}
      animation={"enter"}
    >
      <div className="h-100 w-100 flex items-center justify-center flex-column">
        <h3 className="mt0 mb2 midnight-color">Creating your Professional Email(s)</h3>
        <p className="mv0">This will not take long</p>
        <div className="mv32px flex items-center w-100">
          <div className="flex w-100">
            <LinearProgress value={progress} />
          </div>
          <span className="ml3 progress-percent">{progress}%</span>
        </div>
      </div>
    </Modal>
  )
}

export default WorkspaceEmailProgressionModal;