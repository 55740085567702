import React from "react";
import { useSelector, useDispatch } from "react-redux";
import arrowb from "../../Assets/forwardb.png";
import close from "../../Assets/close.png";
import "./styles.scss";

export default function ProductItem(props) {
  const dispatch = useDispatch();
  return (
    <div
      className="product-header"
      style={props.isBlack ? { backgroundColor: "#000" } : null}
    >
      <img
        src={props.showSearch ? close : arrowb}
        onClick={() => {
          if (props.showSearch) {
            props.toggleSearch();
          } else {
            props.handleNavigate();
          }
        }}
      />
      <label>{props.title}</label>
    </div>
  );
}
