import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { verifyGstForPurchase } from "../../../Redux/Actions";
import {
  VERIFY_GST_API_ACTIONS,
  GST_STATES,
  EDIT_GST_ICON,
  REMOVE_GST_ICON,
  UPDATE_GST_OPTIONS,
  VAILD_GST_REGEX,
} from "../constants";

import "../style.scss";
import { AddIcon } from "../svgIcons";

const GSTInput = (props) => {
  const { prePaymentData, show } = props;
  const [gstStatus, setGstStatus] = useState(GST_STATES.ADD);
  const [currentGstNumber, setCurrentGstNumber] = useState("");
  const [showInvalidGstError, setShowInvalidGstError] = useState(false);
  const [showUpdateGstMenu, setShowUpdateGstMenu] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show && prePaymentData.subscription_payment_id > 0) {
      verifyGst();
    }
  }, [prePaymentData.subscription_payment_id]);

  function verifyGst() {
    dispatch(
      verifyGstForPurchase(
        {
          subscription_payment_id: prePaymentData?.subscription_payment_id,
          action_type: VERIFY_GST_API_ACTIONS.ADD,
        },
        (status, gstNumber) => {
          if (status) {
            if (gstNumber.length > 0) {
              setCurrentGstNumber(gstNumber);
              setGstStatus(GST_STATES.SAVED);
            }
          } else {
            setGstStatus(GST_STATES.ACTIVE);
          }
        }
      )
    );
  }

  function updateGSTNumber(gst) {
    if (gst.length != 15) {
      setShowInvalidGstError(false);
    }
    const reg = VAILD_GST_REGEX;
    if (gst.length == 15 && !reg.test(gst)) {
      setShowInvalidGstError(true);
      return;
    }
    setCurrentGstNumber(gst);
  }

  function _renderUpdateGstNumberMenu() {
    return (
      <div className="update-menu pointer">
        <div
          className="edit-gst-number"
          onClick={() => handleUpdateBtn(UPDATE_GST_OPTIONS.EDIT)}
        >
          <img
            className="edit-gst-number-icon"
            src={EDIT_GST_ICON}
            alt="Edit GST Number"
          />
          <p className="edit-gst-number-text">Edit number</p>
        </div>
        <div
          className="remove-gst-number pointer"
          onClick={() => handleUpdateBtn(UPDATE_GST_OPTIONS.REMOVE)}
        >
          <img
            className="remove-gst-number-icon"
            src={REMOVE_GST_ICON}
            alt="Remove GST Number"
          />
          <p className="remove-gst-number-text">Remove</p>
        </div>
      </div>
    );
  }

  function handleSaveChangeBtn() {
    if (gstStatus === GST_STATES.SAVED) {
      setShowUpdateGstMenu(!showUpdateGstMenu);
    } else if (gstStatus === GST_STATES.ACTIVE) {
      setShowInvalidGstError(false);
      dispatch(
        verifyGstForPurchase(
          {
            subscription_payment_id: prePaymentData?.subscription_payment_id,
            action_type: VERIFY_GST_API_ACTIONS.UPDATE,
            gst_number: currentGstNumber,
          },
          (status, gstNumber) => {
            if (status) {
              if (gstNumber.length > 0) {
                setCurrentGstNumber(gstNumber);
                setGstStatus(GST_STATES.SAVED);
              }
            } else {
              setGstStatus(GST_STATES.ACTIVE);
              setShowInvalidGstError(true);
            }
          }
        )
      );
    }
  }

  function handleUpdateBtn(type) {
    if (gstStatus === GST_STATES.SAVED) {
      if (type === UPDATE_GST_OPTIONS.EDIT) {
        setGstStatus(GST_STATES.ACTIVE);
      } else if (type === UPDATE_GST_OPTIONS.REMOVE) {
        dispatch(
          verifyGstForPurchase(
            {
              subscription_payment_id: prePaymentData?.subscription_payment_id,
              action_type: VERIFY_GST_API_ACTIONS.REMOVE,
            },
            () => {
              setGstStatus(GST_STATES.ACTIVE);
              setCurrentGstNumber("");
            }
          )
        );
      }
      setShowUpdateGstMenu(false);
    }
  }

  function handleAddGstBtn() {
    setGstStatus(GST_STATES.ACTIVE);
  }

  const GSTOnboardText = () => {
    return (
      <div className="gst-container-add" onClick={handleAddGstBtn}>
        <div className="gst-container-add-container">
          <AddIcon />
          <span className="gst-container-add-heading">
            Add GST to avail tax benefits
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {gstStatus == GST_STATES.ADD ? (
        <GSTOnboardText />
      ) : (
        <div className="gst-container">
          <div className="gst-header-input">
            <div className="gst-header">
              <h3 className="gst-header-text">GSTIN</h3>
              <div className="gst-header-optional-container">
                <div className="gst-header-optional">
                  <p className="gst-header-optional-text">OPTIONAL</p>
                </div>
              </div>
            </div>
            <div
              className={`input-container ${
                gstStatus === GST_STATES.SAVED ? "input-saved" : ""
              } ${isFocused ? "focused" : ""}`}
            >
              <input
                className={`gst-input ${
                  gstStatus === GST_STATES.SAVED ? "input-saved" : ""
                }`}
                key="gst-input"
                id="gst-input"
                type="text"
                placeholder="Enter GST Number"
                maxLength={15}
                onChange={(e) => updateGSTNumber(e.target.value)}
                value={currentGstNumber}
                disabled={gstStatus === GST_STATES.SAVED}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              <label
                htmlFor="gst-input"
                className={`update-btn pointer ${
                  currentGstNumber.length == 15 ? "update-enabled" : ""
                } ${gstStatus === GST_STATES.SAVED ? "update-saved" : ""}`}
                onClick={handleSaveChangeBtn}
              >
                {gstStatus === GST_STATES.SAVED
                  ? "Change"
                  : currentGstNumber.length > 0
                  ? "Save"
                  : ""}
              </label>
            </div>
            {showInvalidGstError && (
              <div className="invalid-gst-error">
                Please enter a valid GST number
              </div>
            )}
          </div>
        </div>
      )}
      {gstStatus == GST_STATES.SAVED &&
        showUpdateGstMenu &&
        _renderUpdateGstNumberMenu()}
    </>
  );
};

export default React.memo(GSTInput);
