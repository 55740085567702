import React from "react";
import "./styles.scss";

export default function Button(props) {
  return (
    <button className={"custom-btn " + props.className} {...props}>
      {props.children}
    </button>
  );
}
Button.defaultProps = {
  className: ""
};
